<template>
    <div class="instructions container">
        <Table />  
        <div class="instructions__right">
            <Bread :title1="'Главная'" :title2="'Иструкции'" :link1="'/'"/>
            <div class="instructions__right__title">
                <p>Инструкции</p>
            </div>
            <div class="instructions__right__search">
                <div class="instructions__right__search__in">
                    <input type="search" placeholder="Поиск по инструкциям" @keydown.enter="searchODIT" v-model="searchQuery"/>
                    <button type="submit"></button>
                </div>
                <div class="instructions__right__search__button">
                    <button @click="searchODIT">Найти</button>
                </div>
            </div> 
            <div class="instructions__right__values" v-if="newResults == false">
                <div class="instructions__right__values__in" v-for="item in data.data" :key="item">
                    <div class="instructions__right__values__in__top">
                        <div class="instructions__right__values__in__top__image">
                            <img :src="item.image"/>
                        </div>
                        <div class="instructions__right__values__in__top__logo">
                            <img src="@/assets/icons/logoinst.svg"/>
                        </div>
                    </div>
                    <div class="instructions__right__values__in__name">
                        <p>{{ item.title }}</p>
                    </div>
                    <a :href="item.file"  target="_blank" class="instructions__right__values__in__bot"><button>
                        <img src="@/assets/icons/download.svg"/>
                        <p>Скачать</p>
                    </button></a>
                </div>
            </div>
            <p v-if="newResults == true">Результаты поиска по <span style="color: #006495;">"{{ searchQuery }}"</span></p>
            <div class="instructions__right__values" v-if="newResults == true">
                <div class="instructions__right__values__in" v-for="item in searchResults" :key="item" v-if="searchResults.length > 0">
                    <div class="instructions__right__values__in__top">
                        <div class="instructions__right__values__in__top__image">
                            <img :src="item.image"/>
                        </div>
                        <div class="instructions__right__values__in__top__logo">
                            <img src="@/assets/icons/logoinst.svg"/>
                        </div>
                    </div>
                    <div class="instructions__right__values__in__name">
                        <p>{{ item.title }}</p>
                    </div>
                    <a :href="item.file" target="_blank" class="instructions__right__values__in__bot">
                        <button>
                            <img src="@/assets/icons/download.svg"/>
                            <p>Скачать</p>
                        </button>
                    </a>
                </div>
                <div v-else>
                    <p>Нет совпадений</p>
                </div>
            </div>  
            <div class="instructions__right__bot">
                <vue-awesome-paginate
                    v-if="pageCount"
                    :total-items="2 * pageCount"
                    v-model="currentPage"
                    @click="pageChanged"
                />
            </div>
        </div>
    </div>
</template>
<script>
import Table from '@/components/Table.vue';
import axios from 'axios';
import Bread from '@/components/Bread.vue';
import { toast } from 'vue3-toastify';
import "vue-awesome-paginate/dist/style.css";
import { mapMutations } from 'vuex';
export default {
    components: {
        Table, Bread 
    },
     data(){
        return{
            currentPage: 1,
            data: false,
            pageCount: '',
            searchQuery: "",
            searchResults: [],
            newResults: false,
        }
     },
     async created() {
        this.currentPage = this.$route.query.page ? parseInt(this.$route.query.page)  : 1
        this.getPage()    
    },
    methods: {
        ...mapMutations(['SET_LOADING']),
        async pageChanged(page) { 
            await this.$router.push({ query: {page:page}})    
            this.getPage()
        },
      async getPage() {    
        this.SET_LOADING(true) 
            await this.$axios.get(`pages/instructions?lang=ru&page=${this.$route.query.page ? this.$route.query.page : 1}`)
            .then(res => {
                this.data = res.data.instructions
                this.pageCount = res.data.instructions.last_page
                this.SET_LOADING(false) 
            })
            .catch(err => {
                this.SET_LOADING(false) 
            })        
        },
        searchODIT() {
            if (!this.searchQuery.trim()) {
            // Если searchQuery пустое, не выполняем поиск
            this.newResults = false;
            this.searchResults = [];
            return;
            }

            const apiUrl = `https://back-hayward.mydev.kz/api/V1/pages/instructions/search?text=${encodeURIComponent(this.searchQuery)}`;
            axios.get(apiUrl)
            .then(response => {
                this.searchResults = response.data.instructions.data;
                this.pageCount = response.data.instructions.last_page;
                this.newResults = true;
            })
            .catch(error => {
                toast.error('При поиске произошла ошибка');
            });
        },
    },
    watch: {
    // Наблюдатель за изменениями в searchQuery
    searchQuery(newVal) {
      // Если searchQuery пустое, устанавливаем newResults в false
      this.newResults = !newVal.trim();
      if (!this.newResults) {
        // Если searchQuery пустое, очищаем результаты поиска
        this.searchResults = [];
      } else {
        // Если searchQuery не пустое, выполняем поиск
        this.searchODIT();
      }
    },
  },


}
</script>
<style lang="scss" scoped>
.instructions__right__search__in input[type="search"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: none;
    /* Добавьте другие необходимые стили */
}
.instructions__right__search__in input[type="search"]::-webkit-search-cancel-button {
    display: none;
}
.instructions{
    display: flex;
    justify-content: space-between;
    padding-top: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 1.1285))), 25px);
    padding-bottom: min(max(80px, calc(5rem + ((1vw - 7.68px) * 6.0764))), 150px);
    &__right{
        width: 74.07%;
        display: flex;
        flex-direction: column;
        gap: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 1.1285))), 25px);
        @media(max-width:768px){
                    width: 100%;
                }
        &__title{
            font-size:  min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.6944))), 23px);
            font-weight: 600;
            @media(max-width:768px){
                    font-size: 15px;
                }
        }
        &__search{
            display: flex;
            font-size: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.5208))), 18px);
            gap: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.5208))), 15px);
            @media(max-width:768px){
                    font-size: 13px;
                }
            &__in{
                width: 30%;
                position: relative;
                @media(max-width:768px){
                    width: 100%;
                }
                input{
                    padding:0px min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 0.7813))), 27px) 0px 10px;
                    height: min(max(20px, calc(1.25rem + ((1vw - 7.68px) * 1.3021))), 35px);
                    border: 1px solid #36363633;
                    border-radius: 10px;
                    width: 100%;
                    font-size: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.5208))), 16px);
                    outline: none;
                    @media(max-width:768px){
                        font-size: 13px;
                    }
                }
                button{
                    position: absolute;
                    font-size: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.5208))), 16px);
                    top: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.6076))), 17px);
                    right: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.5208))), 20px);
                    transform: translateY(-50%);
                    width: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px); /* Установите ширину и высоту кнопки по вашему желанию */
                    height: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
                    background: url('@/assets/icons/search.svg') no-repeat center center; /* Укажите путь к вашей картинке */
                    background-size: contain; /* Размер изображения */
                    border: none;
                    padding: 0;
                    cursor: pointer;
                    @media(max-width:768px){
                        font-size: 13px;
                    }
                }
            }
            &__button{
                button{
                    border: none;
                    background: #006495;
                    height: min(max(20px, calc(1.25rem + ((1vw - 7.68px) * 1.3021))), 35px);
                    color: white;
                    padding: 0px min(max(24px, calc(1.5rem + ((1vw - 7.68px) * 1.2153))), 38px);
                    border-radius: 10px;
                    cursor: pointer;
                    font-size: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.5208))), 16px);
                }
            }
        }
        &__values{
            display: grid;
            grid-template-columns: repeat(auto-fill, min(max(150px, calc(9.375rem + ((1vw - 7.68px) * 4.3403))), 200px));
            gap: min(max(40px, calc(2.5rem + ((1vw - 7.68px) * 3.0382))), 75px);
            row-gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 1.3021))), 30px);
            @media(max-width:768px){
                gap: 20px;
                grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
            }
            &__in{
                display: flex;
                flex-direction: column;
                width: 100%;
                gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.8681))), 20px);
                &__top{
                    box-shadow: 0px 4px  min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.8681))), 20px) 0px #00000040;
                    border-radius: 10px;
                    &__image{
                        height: min(max(150px, calc(9.375rem + ((1vw - 7.68px) * 13.0208))), 300px);
                        width: 100%;
                        @media(max-width:768px){
                            height: 200px;
                        }
                        img{
                            width: 100%;
                            border-radius: 10px 10px 0px 0px;
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                    &__logo{
                        padding: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.6944))), 20px) 0px;
                        display: flex;
                        justify-content: center;
                        img{
                            width: 80%;
                        }
                    }
                }
                &__name{
                    flex-grow: 1;
                    p{
                        text-align: center;
                        font-size: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.5208))), 18px);
                            @media(max-width:768px){
                                font-size: 15px;
                            }
                        }
                }
                    &__bot{
                        width: 70%;
                        margin-left: auto;
                        margin-right: auto;
                        @media(max-width: 768px){
                            width: 80%;
                        }
                    button{
                        width: 100%;
                        display: flex;
                        font-size: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.5208))), 16px);
                        cursor: pointer;
                        gap: 10px;
                        border: none;
                        padding: min(max(6px, calc(0.375rem + ((1vw - 7.68px) * 0.434))), 11px) 0px;
                        background: #006495;
                        color: white;
                        justify-content: center;
                        align-items: center;
                        border-radius: 10px;
                        @media(max-width:768px){
                            font-size: 15px;
                            padding: 7px 0px;
                        }
                        img{
                            filter: brightness(0) saturate(100%) invert(95%) sepia(1%) saturate(141%) hue-rotate(34deg) brightness(118%) contrast(100%);
                            width:min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.6944))), 20px);
                            height: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.6944))), 20px);
                        }
                        
                            text-decoration: none;
                            color: white;
                    }
                }
            }
        }
        &__bot{
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
</style>