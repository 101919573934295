<template>
  <div class="home container">
    <div class="home__block1">
      <Table />
      <div class="home__block1__right">
        <div class="desctop">
          <div class="home__block1__right__banner">
              <swiper
                :key="name"
                :slidesPerView="1" 
                :pagination="{ clickable: true, dynamicBullets: true }"
                :spaceBetween="0"
                :lazy="true"
                :speed="2000"
                :loop="true" 
                :autoplay="{ delay: 1000, disableOnInteraction: false }"
            >
                <swiper-slide v-for="item in banners" :key="item.id">
                    <img :src="item.image" class="sliderImage"/>
                </swiper-slide>
            </swiper>
          </div>
        </div>
        <div class="mobile">
          <div class="home__block1__right__mobilebanner">
            <div class="home__block1__right__mobilebanner__in" v-for="item in newMobileCategories" :key="item" :style="'background-image: url(\'' + item.image + '\');'" @click="$router.push('/catalog'), catalogQuery(item.id)"> 
              <p>{{ item.title }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="home__block5" style="display: flex; flex-direction: column; gap: 1rem;">
      <div class="home__block1__right__values">
          <div class="home__block1__right__values__title">
            <p>Новинки</p>
          </div>
          <div class="home__block1__right__values__bot">
            <div class="home__block1__right__values__bot__left">
              <div v-for="item in newCategories" :key="item" @click="$store.state.categoryNewId = item.id, selectCategory(item)" style="cursor: pointer;" :class="{activeCategory: item.id == $store.state.categoryNewId}">{{ item.title }}</div>
            </div>
            <div class="home__block1__right__values__bot__right">
              <p style="cursor: pointer;">Смотреть все</p>
            </div>
          </div>
      </div>
      <div class="desctop">
          <div class="home__block1__right__products" v-if="selectedCategory?.products.length > 0">
            <CardProductVue :item="item" v-for="item in selectedCategory.products.slice(0, 4)" :key="item"/>
            <CardProductVue :item="item" v-for="item in selectedCategory.products.slice(0, 2)" :key="item"/>
          </div>
          <div class="home__block1__right__products" v-else>
            <p>Нет товаров в наличии</p>
          </div>
      </div>
      <div class="mobile">
          <div class="mobileProducts">
            <swiper
                :key="name"
                :slidesPerView="1.8" 
                :spaceBetween="25"
                :lazy="true"
                :speed="2"
                :loop="true"
            >
                <swiper-slide v-for="item in selectedCategory?.products" :key="item">
                  <CardProductVue :item="item"/>
                </swiper-slide>
            </swiper>
          </div>
          <div class="mobileSwiperButton" @click="$router.push('/catalog')" style="margin-top: 20px;">
            <button>Смотреть все</button>
          </div>
      </div>
    </div>
  </div>
  <div class="home__block2">
    <div class="cont2">
      <p>VERSATILE DESIGN</p>
      <p>EXPLORE WHAT MAKES A HAYWARD POOL A BETTER POOL</p>
    </div>
      <div class="cont">
        <div class="arrowLeft1"> 
          <img src="@/assets/icons/arrowLeft2.svg"/>
        </div>
        <swiper
        :key="name"
              :slidesPerView="3" 
              :lazy="true"
              :loop="true"
              :navigation="{ 
                  prevEl: '.arrowLeft1', 
                  nextEl: '.arrowRight1'
          }" >
            <swiper-slide v-for="item in home.sliders" :key="item.id" class="home__block2__swiper">
              <div class="home__block2__swiper__right"> 
                <img :src="item.image"/>
                <router-link :to="`/pages/${item.id}`">
                  <button class="abs">{{ item.title }}</button>
                </router-link>
              </div>
            </swiper-slide>
        </swiper>
        <div class="arrowRight1"> 
          <img src="@/assets/icons/arrowLeft1.svg"/>
        </div>
      </div>
  </div>
  <div class="container">
    <div class="home__block3" v-if="category?.products?.length > 0">
      <div class="home__block3__title">
        <p>{{ category.title }}</p>
      </div>
      <Products classname="Product1" :productsArray="category.products"/>
    </div>
  </div>
  <div class="home__block4">
      <div class="home__block4__right">
        <div class="home__block4__right__title">
          ПОЛУЧИТЬ ПРОФЕССИОНАЛЬНУЮ <br/>КОНСУЛЬТАЦИЮ
        </div>
        <div class="home__block4__right__input">
          <input type="text" placeholder="Имя:" v-model="v$.name.$model"/>
          <template v-for="(error) of v$.name.$errors" :key="error">
              <p class="errorValid">{{ error.$message }}</p>
          </template>
        </div>
        <div class="home__block4__right__input">
          <input type="text" placeholder="Номер телефона:" v-model="v$.phone.$model" v-mask="'+7 (###) ###-##-##'"/>
          <template v-for="(error) of v$.phone.$errors" :key="error">
              <p class="errorValid">{{ error.$message }}</p>
          </template>
        </div>
        <button @click="sendData()">Оставить заяку</button>
        <div class="home__block4__right__bot">
          <div class="home__block4__right__bot__input">
            <input type="checkbox" v-model="v$.check.$model"/>
            <p>Cогласен/а на обработку персональных данных</p>
          </div>
          <template v-for="(error) of v$.check.$errors" :key="error">
              <p class="errorValid">{{ error.$message }}</p>
          </template>
        </div>
      </div>
    </div>
</template>

<script>
import Products from '@/components/Products.vue';
import Search from '@/components/Search.vue';
import Table from '@/components/Table.vue';
import { Swiper, SwiperSlide, } from "swiper/vue";
import "swiper/css";
import axios from 'axios'
import "swiper/css/pagination";
import "swiper/css/navigation";
import { toast } from 'vue3-toastify';
import SwiperCore, { A11y, Autoplay } from "swiper";
import { Navigation, Pagination } from 'swiper';
import "swiper/swiper.min.css";
import CardProductVue from '@/components/CardProduct.vue';
import { useVuelidate } from '@vuelidate/core'
import { required, minLength, helpers, email, sameAs  } from "@vuelidate/validators";
import { mask } from "vue-the-mask";
import { mapMutations } from 'vuex';
SwiperCore.use([Navigation, Pagination, A11y, Autoplay])
export default {
  setup () {
    return { v$: useVuelidate() }
  },
  components: {
    Products, Swiper, SwiperSlide,Search, Table,
    CardProductVue
  },
  data() {
    return {
      name: '',
      nameProduct: '',
      phone: '',
      check: '',
      home:false,
      banners: false,
      category: false,
      homeContent: false,
      selectedCategory: null,
      categoryId: null,
      newCategories: false,
      newMobileCategories: false,
    }
  },
  directives: { mask },
  validations() {
    return {
      name: {
          required: helpers.withMessage('Обязательное поле', required),
          minLength: helpers.withMessage('Минимальное количество символов: 3',minLength(3)),
      },
      phone: {
          required: helpers.withMessage('Обязательное поле', required),
      },
      check : {
        required: helpers.withMessage('Обязательное поле', required),
      }
    };
  },
  async created() {
        this.getPage()    
    },
    methods: {
      ...mapMutations(['SET_LOADING']),
      sendData(){
        if (!this.v$.$invalid) {
            const back = {
              phone: this.phone,
              name: this.name,
              email:  '',
              message: ''
            };
            axios
                .post("https://back-hayward.mydev.kz/api/V1/applications/create", back, {
                headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
            })
                    .then((response) => {
                      if (response.status === 201) {
                        toast('Заявка успешно отправлена')
                      } 
                      else {
                        toast(err?.response?.message)
                    }
                    })
                    .catch((e) => { 
                      console.log(e);
                    });
        }
        else{
            this.v$.$touch();
        }
      },
      selectCategory(category) {
        this.selectedCategory = category;
      },
      async getPage() {  
            this.SET_LOADING(true)  
            await this.$axios.get(`pages/home?lang=ru`)
            .then(res => {
                this.home = res.data
                this.banners = res.data.banners
                this.category = res.data.importantCategory
                this.homeContent = res.data.homeContent
                this.newCategories = res.data.newCategories
                this.selectedCategory = res.data.newCategories[0]
                this.$store.state.categoryNewId = res.data.newCategories[0].id
            })
            .catch(err => {
              this.SET_LOADING(false)
            })      
            await this.$axios.get(`get-header?lang=ru`)
            .then(res => {
              this.newMobileCategories = res.data.categories
              this.SET_LOADING(false)
            })
            .catch(err => {
            })   
        },
        catalogQuery(id) {
          this.categoryId = id
          const currentQuery = { ...this.$route.query };
          if(id){
            currentQuery.category_id = this.categoryId;
          }else{
            delete currentQuery['category_id']
          }
          this.$router.push({ path: '/catalog', query: { ...currentQuery } });  
        }
    },
}
</script>
<style lang="scss" scoped>
.sliderImage{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.arrowLeft1, .arrowRight1{
  color: #fff;
  width: 2rem;
  @media(max-width:768px){
    display: none;
  }
}
.mobileSwiperButton{
  display: flex;
  justify-content: center;
  button{
    width: 50%;
    background: #006495;
    border-radius: 10px;
    color: white;
    padding: 12px 0px;
    border: none;
  }
}
.errorValid {
  font-size: 12px !important;
  color: #FF2A62 !important;
  margin-top: 8px;
  position: absolute;
  top: 100%;
}
.activeCategory{  
  border: 1px solid #006495!important;
  color: #006495 !important ;
}
.checks {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__in {
    display: flex;
    gap: 10px;
    color: #4E4E4E;
    font-size: 13px;
    align-items: center;
  }
}

.line {
  border: 1px solid #0000001A;
}

.heightScroll {
  overflow: auto;
  height: 100px;
}
.swiperForMobile{
  height: 260px;
  width: 100%;
}
.home {
  &__block1 {
    display: flex;
    justify-content: space-between;
    padding-top: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 1.1285))), 25px);
    margin-bottom: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 1.1285))), 25px);
    &__left {
      width: 22.63%;
      box-shadow: 0px 4px 16px 0px #00000026;
      border-radius: 10px;
      height: fit-content;
      padding-bottom: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 1.1285))), 25px);
      &__title {
        padding: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 1.1285))), 25px);
        background: #006495;
        color: white;
        border-radius: 10px 10px 0px 0px;
        font-size:  min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.5208))), 18px);
      }

      &__catalog {
        display: flex;
        flex-direction: column;
        gap: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.6076))), 16px);
        padding: 0px min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 1.1285))), 25px);
        padding-top: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 1.1285))), 25px);
        color: #4E4E4E;
        font-size: min(max(8px, calc(0.5rem + ((1vw - 7.68px) * 0.434))), 13px);

        &__in {
          display: flex;
          flex-direction: column;
          gap: 16px;
        }
      }

      &__filter {
        display: flex;
        flex-direction: column;
        gap: 25px;
        padding: 0px 25px;
        padding-top: 50px;

        &__title {
          display: flex;
          gap: 10px;
        }

        &__values {
          display: flex;
          flex-direction: column;
          gap: 25px;

          &__in {
            display: flex;
            flex-direction: column;
            gap: 20px;
          }
        }
      }
    }
    &__right{
      width:74.07%;
      display: flex;
      flex-direction: column;
      gap: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);
      max-height: 100%;
      @media(max-width: 768px){
        width: 100%;
        gap: 25px;
      }
      .desctop{
        height: 100%;
      }
      &__mobilebanner {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 10px;

            p {
              text-align: center;
              color: white;
              font-weight: 600;
              font-size: 16px;
              z-index: 1;
              text-transform: uppercase;
            }

            &__in {
              position: relative; /* Добавлено для позиционирования псевдоэлемента */
              height: 150px;
              background-size: cover;
              background-repeat: no-repeat;
              display: flex;
              background-position: center;
              justify-content: center;
              align-items: center;
              border: 1px solid #006495;
              border-radius: 10px;
              &::before {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 65%; /* Высота оверлея - половина блока */
                background: linear-gradient(to top, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
                border-radius: 0px 0px 10px 10px;
              }
            }
          }
      &__values{
        display: flex;
        flex-direction: column;
        gap:  min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 1.1285))), 25px);
        @media(max-width: 768px){
          gap: 12px;
        }
        &__title{
          font-weight: 600;
          font-size: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.6076))), 22px);
          @media(max-width:768px){
            font-size: 17px;
          }
        }
        &__bot{
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: min(max(11px, calc(0.6875rem + ((1vw - 7.68px) * 0.434))), 16px);
          &__left{
            display: flex;
            gap: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 1.1285))), 25px);
            @media(max-width:768px){
              font-size: 12px;
              flex-wrap: wrap;
              gap: 10px;
            }
            div{
              padding: 6px min(max(8px, calc(0.5rem + ((1vw - 7.68px) * 0.434))), 13px);
              border: 1px solid #36363680;
              border-radius: 5px;
              color: #36363680;
            }
          }
          &__right{
            color: #006495;
            text-decoration: underline;
            @media(max-width:768px){
              display: none;
            }
          }
        }
      }
      &__products{
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(250px,  1fr));
        row-gap: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);
        font-size: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.5208))), 18px);
      }
      &__banner{
        width: 100%;
        aspect-ratio: 2.65;
        height: 100%;
        .swiper{
          height: 100%;
        }
      }
    }
  }
  &__block2{
    margin-top: 8rem;
    display: flex;
    flex-direction: column;
    background-image: url('@/assets/icons/swiper.png');
    background-size:cover;
    background-repeat: no-repeat;
    padding: min(max(35px, calc(2.1875rem + ((1vw - 7.68px) * 3.0382))), 70px) 10px;
    align-items: center;
    gap: min(max(20px, calc(1.25rem + ((1vw - 7.68px) * 1.7361))), 40px);
    // background: #ECF5FF;
    @media(max-width:768px){
      margin-top: 25px;
      padding: 25px;
    }
    .cont{
      width: 100%;
      display: flex;
      align-items: center;
      gap:1rem;
    }
    .cont2{
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 1rem;
      p{
        color: white;
        font-size: 25px;
        font-weight: 700;
        text-align: center
      }
    }
    &__swiper{
      display: flex;
      justify-content: center;
      @media(max-width:768px){
        flex-direction: column;
        align-items: center;
      }
      &__right{
        position: relative;
        width: 400px;
        height: 400px;
        @media(max-width:1024px){
          width: 280px;
          height: 300px;
        }
        @media(max-width:768px){
          width: 100%;
        }
        .abs{
          position: absolute;
          bottom: 1rem;
          left: 1rem;
          background: white;
          border: none;
          color: #006495;
          font-size: 12px;
          padding: 1rem 2rem;
          border-radius: 2rem;
        }
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 1rem;
          z-index: 0;
        }
        @media(max-width:768px){
          margin-top: 15px;
          height: 200px;
          img{
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  &__block3{
    display: flex;
    flex-direction: column;
    gap: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);
    @media(max-width:768px){
        gap: 25px;
      }
    &__title{
      font-size:  min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.6076))), 22px);
      font-weight: 700;
      padding-top: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);
      @media(max-width:768px){
        padding-top: 25px;
        font-size: 21px;
      }
    }
  }
  &__block4{
    margin-top: 20px;
    background-image: url('@/assets/icons/newBanner.png');
    max-height: 500px;
    aspect-ratio: 3.05;
    background-size: cover;
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    @media(max-width:768px){
        margin-top: 25px;
        flex-direction: column;
      }
    // &__left{
    //   display: flex;
    //   width: 50%;
    //   flex-direction: column;
    //   align-content: start;
    //   @media(max-width:768px){
    //     width: 100%;
    //   }
    //   &__title{
    //     font-size: min(max(21px, calc(1.3125rem + ((1vw - 7.68px) * 1.6493))), 40px);
    //     font-weight: 800;
    //     padding: 25px  min(max(50px, calc(3.125rem + ((1vw - 7.68px) * 4.3403))), 100px);
    //     @media(max-width:768px){
    //       padding: 25px 13px;
    //       font-size: 21px;
    //     }
    //   }
    //   &__sub{
    //     padding: 0px  min(max(50px, calc(3.125rem + ((1vw - 7.68px) * 4.3403))), 100px);
    //     font-size: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.5208))), 18px);
    //     color: #36363680;
    //     line-height: min(max(21px, calc(1.3125rem + ((1vw - 7.68px) * 0.7813))), 30px);
    //     @media(max-width:768px){
    //       padding: 0px 13px;
    //       font-size: 15px;
    //     }
    //   }
    //   img{
    //     width: min(max(250px, calc(15.625rem + ((1vw - 7.68px) * 15.625))), 430px);
    //     height: min(max(120px, calc(7.5rem + ((1vw - 7.68px) * 7.8125))), 210px);
    //     @media(max-width:768px){
    //       width: 190px;
    //       height: 100px;
    //     }
    //   }
    // }
    &__right{
      // margin-top:  min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 1.7361))), 45px);
      // margin-bottom:  min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 1.7361))), 45px);
      margin-top: 1.5rem;
      margin-bottom:  1.5rem;
      width: 60%;
      // padding: min(max(28px, calc(1.75rem + ((1vw - 7.68px) * 2.4306))), 56px);
      padding: 3rem;
      height: fit-content;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      background: linear-gradient(180deg, rgba(0, 100, 149, 0.8) 0%, rgba(255, 255, 255, 0.2) 100%);

      align-items: center;
      gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 1.3021))), 30px);
      @media(max-width:768px){
        margin-top: 25px;
        margin-bottom: 25px;
        width: 90%;
        padding: 40px;
        gap:20px;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
      }
      &__title{
        font-size: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 0.7813))), 27px);
        color: #FFFFFF;
        text-align: center;
        z-index: 1;
        font-weight: 700;
        @media(max-width:768px){
          font-size: 20px;
        }
      }
      &__input{
        width: 30%;
        position: relative;
        z-index: 1;
        @media(max-width:768px){
          width: 100%;
        }
        input{
        background: none;
        z-index: 1;
        width:100%;
        border: 2px solid #ffffff !important;
        border: none;
        padding: 15px;
        border-radius: 5px;
        outline: none;
        color: #ffffff;
        font-size: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.5208))), 18px);
        @media(max-width:768px){
                        font-size: 13px;
                    }
        &::placeholder{
          color: #ffffff;
          text-align: center;
        }
      }
      }
      button{
        padding: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.8681))), 22px) 0px;
        width: 30%;
        background: #FFFFFF;
        z-index: 1;
        border-radius: 5px;
        color: #006495;
        cursor: pointer;
        font-weight: 700;
        border: none;
        font-size: min(max(11px, calc(0.6875rem + ((1vw - 7.68px) * 0.434))), 16px);
        @media(max-width:768px){
                        font-size: 13px;
                        width: 100%;
                    }
        @media(max-width:768px){
          padding: 12px 0px;
        }
      }
      &__bot{
        z-index: 1;
        position: relative;
        &__input{
          display: flex;
          align-items: center;
          z-index: 1;
          gap: 10px;
          font-size: min(max(11px, calc(0.6875rem + ((1vw - 7.68px) * 0.434))), 16px);
          color: white;
          @media(max-width:768px){
                        font-size: 13px;
                    }
        }
      }
    }
  }
}</style>
