<template>
    <div class="footerBack">
        <div class="footer container">
            <div class="footer__top">
                <div class="footer__top__block1">
                    <router-link to="/"><img src="@/assets/icons/logo.svg"/></router-link>
                </div>
                <div class="footer__top__block2">
                    <div class="footer__top__block2__info">
                        <div class="footer__top__block2__info__in" v-for="item in footer.footerMenus" :key="item">
                            <p style="cursor: pointer;" @click="$router.push(item.page)">{{item.title}}</p>
                        </div>
                    </div>
                </div>
                <div class="footer__top__block3">
                    <p class="footer__top__block3__title">Каталог</p>
                    <div class="footer__top__block3__info" v-for="item in footer.footerCategories" :key="item">
                        <p @click="$router.push(`/catalog?category_id=${item.id}`)" style="cursor: pointer;">{{ item.title }}</p>
                    </div>
                </div>
                <div class="footer__top__block4">
                    <p class="footer__top__block4__title">Контакты</p>
                    <div class="footer__top__block4__info">
                        <p style="font-weight: 700;">Адрес</p>
                        <p style="font-size: 13px;">050045, г.Алматы, Казахстан, ул.Рахмадиева, 3</p>
                        <p style="font-weight: 700; margin-top: 10px;">Оформить заказ</p>
                        <p class="hoveredElement"><a :href="`tel:${footer.footerContact?.phone }`" style="color: inherit; text-decoration: none;font-size: 13px;">{{ footer.footerContact?.phone }}</a></p>
                        <p style="font-weight: 700; margin-top: 10px;">Время работы:</p>
                        <textarea style="resize: none; background: inherit; border: none; color: white; outline: none;" disabled>{{ footer.footerContact?.work_time }}</textarea>
                    </div>
                </div>
                <div class="footer__top__block5">
                    <div class="footer__top__block5__in"  v-for="item in footer.socials" :key="item">
                        <a :href="item.link"><img :src="item.image"/></a>
                    </div>
                </div>
            </div>
            <div class="line"></div> 
            <div class="footer__bot" >
                <div v-for="item in footer.agreements" :key="item">
                    <a :href="item.link"><p>{{ capitalizeFirstLetter(item.title) }}</p></a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            footer: false
        }
    },
    async created() {
        this.getPage()    
    },
    methods: {
        capitalizeFirstLetter(text) {
            if (!text) return '';
            return text.charAt(0).toUpperCase() + text.slice(1);
        },
      async getPage() {    
            await this.$axios.get(`get-footer?lang=ru`)
            .then(res => {
                this.footer = res.data
            })
            .catch(err => {
            })        
        },
    }
}
</script>
<style lang="scss" scoped>
    .footerBack{
        background: #006495;
    }
    .hoveredElement{
        transition: all 0.5s ease;
        &:hover{
            transform: scale(1.05);
        }
    }
    .footer{
        display: flex;
        flex-direction: column;
        color: white;
        // padding: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px) 0px;
        padding: 2rem;
        &__top{
            display: flex;
            justify-content: space-between;
            align-items: start;
            @media(max-width:768px){
                flex-wrap: wrap;
                row-gap: 20px;
            }
            &__block1{
                display: flex;
                flex-direction: column;
                gap: min(max(20px, calc(1.25rem + ((1vw - 7.68px) * 1.3021))), 35px);
                width: 15%;
                a{
                    img{
                        width: 100%;
                        object-fit: cover;
                    }
                }
                @media(max-width:768px){
                    width: 80%;
                    margin-left: auto;
                    margin-right: auto;
                    order: 1;
                }
            }
            &__block2{
                display: flex;
                flex-direction: column;
                gap: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.6944))), 20px);
                width: 15.12%;
                @media(max-width:768px){
                    width: 50%;
                    order: 2;
                }
                &__title{
                    font-weight: 600;
                    font-size: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.5208))), 18px);
                    @media(max-width:768px){
                        font-size: 13px;
                    }
                }
                &__info{
                    display: flex;
                    flex-direction: column;
                    font-size: min(max(11px, calc(0.6875rem + ((1vw - 7.68px) * 0.434))), 16px);
                    gap: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.5208))), 15px);
                    @media(max-width:768px){
                        font-size: 13px;
                    }
                    &__in{
                        display: flex;
                        gap: 10px;
                        width: 100%;
                        align-items: center;
                        transition: all 0.5s ease;
                        &:hover{
                            transform: scale(1.05);
                        }
                        &:first-child{
                            font-weight: 700;
                            font-size: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.5208))), 18px);
                            @media(max-width:768px){
                                font-size: 15px;
                            }
                        }
                    }
                }
            }
            &__block3{
                display: flex;
                flex-direction: column;
                gap: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.6944))), 20px);
                @media(max-width:768px){
                    order: 4;
                }
                &__title{
                    font-size:min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.5208))), 18px);  
                    font-weight: 600;
                    @media(max-width:768px){
                        font-size: 15px;
                    }
                }
                &__info{
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    font-size: min(max(11px, calc(0.6875rem + ((1vw - 7.68px) * 0.434))), 16px);
                    p{
                        transition: all 0.5s ease;
                        &:hover{
                            transform: scale(1.05);
                        }
                    }
                    @media(max-width:768px){
                        font-size: 13px;
                    }
                }
            }
            &__block4{
                display: flex;
                flex-direction: column;
                gap: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.6944))), 20px);
                @media(max-width:768px){
                    order: 3;
                    width: 40%;
                }
                &__title{
                    font-size:min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.5208))), 18px);  
                    font-weight: 600;
                    @media(max-width:768px){
                        font-size: 15px;
                    }
                }
                &__info{
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    font-size: min(max(11px, calc(0.6875rem + ((1vw - 7.68px) * 0.434))), 16px);
                    @media(max-width:768px){
                        font-size: 13px;
                    }
                }
            }
            &__block5{
                display: flex;
                gap: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.6944))), 20px);
                justify-content: start;
                @media(max-width:768px){
                    order: 5;
                    width: 40%;
                }
                &__in{
                    a{
                        img{
                            width: min(max(20px, calc(1.25rem + ((1vw - 7.68px) * 1.7361))), 40px);
                            height: min(max(20px, calc(1.25rem + ((1vw - 7.68px) * 1.7361))), 40px);
                            transition: all 0.5s ease;
                            &:hover{
                                transform: scale(1.05);
                            }
                            @media(max-width:768px){
                                width: 30px;
                                height: 30px;
                            }
                        }
                    }
                }
                &__title{
                    font-size: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.5208))), 18px);
                    font-weight: 600;
                }
                &__info{
                    display: flex;
                    flex-direction: column;
                    gap:  10px;
                   
                }
            }
        }
        .line{
            border: 1px solid white;
            // margin-top: min(max(20px, calc(1.25rem + ((1vw - 7.68px) * 1.7361))), 40px);
            margin-top: 1rem;
        }
        &__bot{
            display: flex;
            justify-content: space-between;
            // margin-top: min(max(20px, calc(1.25rem + ((1vw - 7.68px) * 1.7361))), 40px);
            margin-top: 1rem;
            font-size: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.5208))), 18px);
            a{
                text-decoration: none;
                color: white;
               p{
                transition: all 0.5s ease;
                &:hover{
                    transform: scale(1.05);
                }
               }
            }
            @media(max-width:768px){
                        font-size: 13px;
                    }
            @media(max-width:768px){
                    flex-direction: column;
                    margin-top: 20px;
                    gap: 10px;
                }
        }
    }
</style>