<template>
    <div class="recoveryBack">
        <div class="recovery">
            <div class="recovery__logo">
                <router-link to="/"><img src="@/assets/icons/logo.svg"/></router-link>
            </div>
            <div class="recovery__modal">
                <div class="recovery__modal__title">
                    <p>Восстановление доступа</p>
                </div>
                <div class="recovery__modal__subtitle">
                    <p v-if="isOk == false">Введите ваш email, и мы вышлем вам ссылку по получению нового пароля.</p>
                    <p v-else>Мы отправили вам на email ссылку восстановления пароля.Проверьте вашу почту</p>
                </div>
                <div class="recovery__modal__inputs" v-if="isOk == false">
                    <div class="recovery__modal__inputs__input">
                        <input type="email" placeholder="Email*" v-model="v$.email.$model"/>
                        <template v-for="(error) of v$.email.$errors" :key="error">
                            <p class="errorValid">{{ error.$message }}</p>
                        </template>
                    </div>
                </div>
                <div class="recovery__modal__button" v-if="isOk == false">
                    <button style="cursor: pointer;" @click="sendData()">Далее</button>
                </div>
                <div class="recovery__modal__register" v-if="isOk == false">
                    <p @click="$router.push('/')">На главную</p>
                </div>
                <div class="recovery__modal__gif" v-if="isOk == true">
                    <img src="@/assets/icons/mygif.gif"/>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { useVuelidate } from '@vuelidate/core'
import { required, minLength, helpers, email, sameAs  } from "@vuelidate/validators";
import { mask } from "vue-the-mask";
import axios from 'axios'
import { mapActions } from 'vuex';
import { toast } from 'vue3-toastify';
import { mapMutations } from 'vuex';
export default {
    setup () {
        return { v$: useVuelidate() }
    },
    data(){
        return{
            email: '',
            isOk: false
        }
    },
  validations() {
    return {
      email: {
          required: helpers.withMessage('Обязательное поле', required),
          email: helpers.withMessage('Некорректный email', email),
      },
    };
  },
  methods: {
    sendData() {
        if (!this.v$.$invalid) {
            const back = {
                email: this.email
            };
            this.$axios.post("auth/forgot-password", back)
            .then(res => {
                toast('Успешно отправлено!')
                this.isOk = true
            })
            .catch(err => {
            if (err?.response?.data?.message) {
                toast(err?.response?.data?.message);
            }
            })
        } else {
            this.v$.$touch();
        }
    },
}
}
</script>
<style lang="scss" scoped>
.errorValid {
  font-size: 12px !important;
  color: #FF2A62 !important;
}
    .recoveryBack{
    padding: min(max(75px, calc(4.6875rem + ((1vw - 7.68px) * 6.5104))), 150px) 0px min(max(40px, calc(2.5rem + ((1vw - 7.68px) * 3.4722))), 80px) 0px ;
    z-index: 999;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    @media(max-width:768px){
        padding-top: 30px;
    }
    }
    .recovery{
        display: flex;
        flex-direction: column;
        gap: min(max(20px, calc(1.25rem + ((1vw - 7.68px) * 0.8681))), 30px);
        align-items: center;
        width: 28.28%;
        @media(max-width:768px){
            width: 80%;
        }
        &__logo{
            width: 100%;
            @media(max-width:768px){
                width: 75%;
            }
            img{
                width: 100%;
            }
        }
        &__modal{
            padding: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px) min(max(30px, calc(1.875rem + ((1vw - 7.68px) * 5.2083))), 90px);
            width: 100%;
            border: 1px solid #36363633;
            box-shadow: 0px 4px 16px 0px #00000073;
            border-radius: 20px;
            display: flex;
            flex-direction: column;
            gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 1.3021))), 30px);
            align-items: center;
            &__title{
                font-size: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.8681))), 24px);
                @media(max-width:768px){
                    font-size: 20px;
                }   
            }
            &__subtitle{
                font-size: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.434))), 14px);
                text-align: center;
                color: #00000080;
            }
            &__inputs{
                display: flex;
                flex-direction: column;
                gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.8681))), 20px);
                width: 100%;
                input{
                    width: 100%;
                    border: 1px solid #36363633;
                    padding: 9px 15px;
                    border-radius: 5px;
                    outline: none;
                    font-size: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.8681))), 20px);
                    @media(max-width:768px){
                        font-size: 14px;
                    } 
                }
                p{
                    color: #006495;
                    font-size: min(max(8px, calc(0.5rem + ((1vw - 7.68px) * 0.6944))), 16px);
                    @media(max-width:768px){
                        font-size: 12px;
                    } 
                }
            }
            &__register{
                display: flex;
                gap: 5px;
                margin-top: -20px;
                cursor: pointer;
                color: #006495;
                font-size: min(max(8px, calc(0.5rem + ((1vw - 7.68px) * 0.6944))), 16px);
                @media(max-width:768px){
                        font-size: 12px;
                        margin-top: -10px;
                    } 
            }
            &__button{
                width: 70%;
                button{
                    padding: 10px 0px;
                    font-size: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.8681))), 20px);
                    width: 100%;
                    background: #006495;
                    color: white;
                    border: none;
                    border-radius: 5px;
                }
            }
            &__gif{
                width: 100%;
                img{
                    width: 100%;
                }
            }
        }
    }
</style>