import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueImageZoomer from 'vue-image-zoomer'
import 'vue-image-zoomer/dist/style.css';
import { install } from "vue3-recaptcha-v2";
import VueAwesomePaginate from "vue-awesome-paginate";
import axios from 'axios'
const axiosInstance = axios.create({ 
    baseURL: 'https://back-hayward.mydev.kz/api/V1',
}) 
const app = createApp(App)
.use(install, {
    sitekey: "6Le4jT0pAAAAAG_mU0kf6_vDNEeYx0dfgn5vXdvT",
    cnDomains: false, // Optional, If you use in China, set this value true
  })
.use(store)
.use(VueImageZoomer)
.use(VueAwesomePaginate)
.use(router)
app.config.globalProperties.$axios = { ...axiosInstance } 
app.mount('#app')
