<template>
    <div class="favourites">
      <Bread :title1="'Личный кабинет'" :title2="'Избранные товары'" />  
        <div class="favourites__title">
            <p>Избранные товары</p>
        </div>
        <div class="favourites__products" v-if="getLiked.length > 0">
          <div class="favourites__products__in" v-for="item in getLiked" :key="item">
            <img :src="item.image" class="favourites__products__in__img" />
            <p class="favourites__products__in__title">{{ item.title }}</p>
            <p>Артикул: {{ item.vendor_code }}</p>
            <div class="favourites__products__in__1">
              <p>В наличии</p>
              <img src="@/assets/icons/ok.svg" />
            </div>
            <p class="favourites__products__in__price">от {{ item.price }}</p>
            <div class="favourites__products__in__bot" style="cursor: pointer;">
              <p @click="addFavour(item)">Удалить</p>
            </div>
          </div>
        </div>
        <div v-else class="favourites__products__not">
          <p>У вас нет избранных товаров</p>
        </div>
    </div>
</template>
<script>
import Bread from './Bread.vue';
import { mapMutations, mapGetters, mapActions } from 'vuex';
export default {
    data(){
        return{
        }
    },
    components: {
        Bread
    },
    computed: {
      ...mapGetters(['getLiked']),
    },
    methods: {
      ...mapActions(['addFavour'])
    }
}
</script>
<style lang="scss" scoped>
    .favourites{
        display: flex;
        flex-direction: column;
        gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 1.3021))), 30px);
        &__title{
            color: #363636;
            font-size: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.7813))), 24px);
        }
        &__products{
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(min(max(150px, calc(9.375rem + ((1vw - 7.68px) * 8.6806))), 250px), 1fr));
        gap: min(max(30px, calc(1.875rem + ((1vw - 7.68px) * 2.6042))), 60px);
        row-gap: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);
        &__not{
                font-size:  min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.5208))), 18px);
            }
        &__in{
                border: 1px solid #0000001A;
                border-radius: 10px;
                padding: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 1.1285))), 25px);
                display: flex;
                flex-direction: column;
                gap: min(max(7px, calc(0.4375rem + ((1vw - 7.68px) * 0.434))), 12px);
                font-size: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.5208))), 15px);
                color: #00000080;
                cursor: pointer;
                transition: all 0.5s ease;
                &:hover{
                    transform: scale(1.05);
                }
                &__img{
                    width: 100%;
                    aspect-ratio: 1;
                }
                &__title{
                    width: 80%;
                    color: #232F3E;
                }
                &__1{
                    display: flex;
                    gap: 10px;
                }
                &__price{
                    font-size:  min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.5208))), 18px) !important;
                    color: black !important;
                }
                &__bot{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: red;
                }
            }
        }
    }
</style>    