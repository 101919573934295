import { createStore } from 'vuex'
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import axios from "axios";
export default createStore({
  state: {
    loading: false,
    login: false,
    openAcc: 'data',
    auth: localStorage.getItem("access_token") ? true : false,
    user: {},
    categoryNewId: null,
    basket: JSON.parse(localStorage.getItem('basketItems')) || [],
    favourites: JSON.parse(localStorage.getItem('favourites')) || [],
    openPay: false,
    openBasket: 'basket',
    openTable: false,
    openBurger: false,
    newCategory: '',
    range: {
      id: '',
      min: '',
      max: ''
    },
    ranges: []
  },
  getters: {
    getBasket: (state) => state.basket,
    getLiked: (state) => state.favourites,
    getLoading: (state) => state.loading,
  },
  mutations: {
    SET_AUTH(state, auth) {
      state.auth = auth;
    },
    DELETE_All_BASKET(state) {
      state.basket = []
      localStorage.removeItem('basketItems')
    },
    SET_LOADING(state, value) {
      state.loading = value;
    },
    SET_USER(state, user) {
      state.user = user;
    },
    ADD_BASKET(state, product) {
      const result = state.basket.findIndex(obj => obj.id === product.id);
      if (result < 0) {
        state.basket.push(product);
        toast("Товар добавлен в корзину");
      } else {
        state.basket.splice(result, 1);
        toast.error("Товар удален из корзины");
      }
      localStorage.setItem('basketItems', JSON.stringify(state.basket));
    },
    
    ADD_FAVOUR(state, product) {
      const result = state.favourites.findIndex(obj => obj.id === product.id);
      if (result < 0) {
        state.favourites.push(product);
        toast("Товар добавлен в избранное");
      } else {
        state.favourites.splice(result, 1);
        toast("Товар удален из избранного");
      }
      localStorage.setItem('favourites', JSON.stringify(state.favourites));
    },
  },
  actions: {
    addBasket({ commit, state }, product) {
      const updatedProduct = {
        ...product,
        counter: 1
      };
      const getBasket = JSON.parse(localStorage.getItem('basketItems'));
      if (getBasket) {
        commit('ADD_BASKET', updatedProduct);
      } else {
        localStorage.setItem('basketItems', JSON.stringify([product]));
        commit('ADD_BASKET', updatedProduct);
      }
    },
    addFavour({ commit, state }, product) {
      const getFavour = JSON.parse(localStorage.getItem('favourites'));
      if (getFavour) {
        commit('ADD_FAVOUR', product);
      } else {
        localStorage.setItem('favourites', JSON.stringify([product]));
        commit('ADD_FAVOUR', product);
      }
    },
    logoutUser({ commit }) {
      localStorage.removeItem("access_token");
      commit("SET_USER", {});
      commit("SET_AUTH", false);
    },
    async requestUser({commit}) {
      await axios.get(`https://back-hayward.mydev.kz/api/V1/user/profile`, {
          headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
      })
      .then(res => {
        commit("SET_USER", res);
        commit("SET_AUTH", true);
      })

      .catch(error => {
          commit("SET_USER", {});
          commit("SET_AUTH", false);
          localStorage.removeItem("access_token");
      })
    }, 
  },
  modules: {
  }
})
