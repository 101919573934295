<template>
    <div class="access container">
        <Table />
        <div class="access__right">
            <p>Успешно</p>
        </div>
    </div>
</template>
<script>
import Table from '@/components/Table.vue';
export default {
    components: {
         Table
    },
}
</script>
<style lang="scss" scoped>
    .access{
        display: flex;
        justify-content: space-between;
        padding-top: 25px;
        padding-bottom: 150px;
        &__right{
            width: 74.07%;
            display: flex;
            flex-direction: column;
            gap: 25px;
        }
    }
</style>