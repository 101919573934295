<template>
    <div class="loginBack">
        <div class="login">
            <div class="login__modal">
                <div class="login__modal__title">
                    <p>Войти</p>
                </div>
                <div class="login__modal__inputs">
                    <div class="login__modal__inputs__input">
                        <input type="email" placeholder="Email*" v-model="v$.email.$model"/>
                        <template v-for="(error) of v$.email.$errors" :key="error">
                            <p class="errorValid">{{ error.$message }}</p>
                        </template>
                    </div>
                    <div class="login__modal__inputs__input">
                        <input type="password" placeholder="Введите пароль*" v-model="v$.password.$model"/>
                        <template v-for="(error) of v$.password.$errors" :key="error">
                            <p class="errorValid">{{ error.$message }}</p>
                        </template>
                    </div>
                    <p style="cursor: pointer;" @click="$router.push('/recovery')">Забыли пароль?</p>
                </div>
                <div class="login__modal__button">
                    <button @click="Login" style="cursor: pointer;">Войти</button>
                </div>
                <div class="login__modal__register">
                    <p>Нет аккаунта?</p>
                    <p style="color: #006495; cursor: pointer;" @click="$router.push('/register')">Зарегистрироваться</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { useVuelidate } from '@vuelidate/core'
import { required, minLength, helpers, email, sameAs  } from "@vuelidate/validators";
import { mask } from "vue-the-mask";
import { mapActions } from 'vuex';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
export default {
    setup () {
    return { v$: useVuelidate() }
  },
  data(){
    return{
        email: '',
        password: '',
    }
  },
  validations() {
    return {
      email: {
          required: helpers.withMessage('Обязательное поле', required),
          email: helpers.withMessage('Некорректный email', email),
      },
      password: {
          required: helpers.withMessage('Обязательное поле', required),
          minLength: helpers.withMessage('Пароль должен содержать 8 символов',minLength(8)),
      }
    };
  },
  methods: {
    ...mapActions(["requestUser"]),
    async Login() {
      this.v$.$validate();
      if(!this.v$.$invalid) {
        this.$axios.post("auth/login", {
            email: this.email,
            password: this.password
        })
        .then(res => {
          localStorage.setItem("access_token", res.data.token)
          console.log(res.data.token)
          toast("Вы успешно авторизовались.");
          this.requestUser();
          this.$router.push('/account');
          this.resetModalData();
        })
        .catch(err => {
        if (err?.response?.data?.message) {
            toast('Пользователь не найден');
          }
        })
      }
      else {
        toast("Заполните обязательные поля.");
      }
    },
    resetModalData() {
      this.email = "",
      this.password = "",
      this.v$.$reset()
    },
  }
}
</script>
<style lang="scss" scoped>
.errorValid {
  font-size: 12px !important;
  color: #FF2A62 !important;
}
    .loginBack{
    padding: min(max(75px, calc(4.6875rem + ((1vw - 7.68px) * 6.5104))), 150px) 0px min(max(40px, calc(2.5rem + ((1vw - 7.68px) * 3.4722))), 80px) 0px ;
    z-index: 999;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    }
    .login{
        display: flex;
        flex-direction: column;
        gap: min(max(20px, calc(1.25rem + ((1vw - 7.68px) * 0.8681))), 30px);
        align-items: center;
        width: 28.28%;
        @media(max-width:768px){
            width: 80%;
        }
        &__logo{
            width: 100%;
            @media(max-width:768px){
                width: 75%;
            }
            img{
                width: 100%;
            }
        }
        &__modal{
            padding: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px) min(max(30px, calc(1.875rem + ((1vw - 7.68px) * 5.2083))), 90px);
            width: 100%;
            border: 1px solid #36363633;
            box-shadow: 0px 4px 16px 0px #00000073;
            border-radius: 20px;
            display: flex;
            flex-direction: column;
            gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 1.3021))), 30px);
            align-items: center;
            &__title{
                font-size: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px);
                @media(max-width:768px){
                    font-size: 20px;
                }   
            }
            &__inputs{
                display: flex;
                flex-direction: column;
                gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.8681))), 20px);
                width: 100%;
                padding-top: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.8681))), 20px);
                input{
                    width: 100%;
                    border: 1px solid #36363633;
                    padding: 9px 15px;
                    border-radius: 5px;
                    outline: none;
                    font-size: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.7813))), 21px);
                    @media(max-width:768px){
                        font-size: 14px;
                    } 
                }
                p{
                    color: #006495;
                    font-size: min(max(11px, calc(0.6875rem + ((1vw - 7.68px) * 0.7813))), 20px);
                    @media(max-width:768px){
                        font-size: 12px;
                    } 
                }
            }
            &__register{
                display: flex;
                gap: 5px;
                margin-top: -20px;
                font-size: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.5208))), 18px);
                @media(max-width:768px){
                        font-size: 12px;
                        margin-top: -10px;
                    } 
            }
            &__button{
                width: 70%;
                button{
                    padding: 10px 0px;
                    font-size: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.5208))), 18px);
                    width: 100%;
                    background: #006495;
                    color: white;
                    border: none;
                    border-radius: 5px;
                }
            }
        }
    }
</style>