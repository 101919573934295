import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import CardView from '@/views/CardView.vue'
import AccountView from '@/views/AccountView.vue'
import AboutView from '@/views/AboutView.vue'
import RegisterViewVue from '@/views/RegisterView.vue'
import LoginView from '@/views/LoginView.vue'
import CatalogViewVue from '@/views/CatalogView.vue'
import InstructionsView from '@/views/InstructionsView.vue'
import PayView from '@/views/PayView.vue'
import DeliveryView from '@/views/DeliveryView.vue'
import DillerView from '@/views/DillerView.vue'
import ContactView from '@/views/ContactView.vue'
import { toast } from 'vue3-toastify';
import SearchResults from '@/views/SearchResults.vue'
import AccessView from '@/views/AccessView.vue'
import ErrorView from '@/views/ErrorView.vue'
import RecoveryViewVue from '@/views/RecoveryView.vue'
import NewPasswordViewVue from '@/views/NewPasswordView.vue'
import PagesView from '@/views/Pages/[id].vue'
import { info } from 'sass'
const routes = [
  {
    path: '/',
    name: '/',
    component: HomeView,
    meta: {
      info: false
    }
  },
  {
    path: '/allresults',
    name: 'allresults',
    component: SearchResults,
    meta: {
      info: false
    }
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: ContactView,
    meta: {
      info: false
    }
  },
  {
    path: '/dealers',
    name: 'dealers',
    component: DillerView,
    meta: {
      info: false
    }
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView,
    meta: {
      info: false
    }
  },
  {
    path: '/delivery',
    name: 'delivery',
    component: DeliveryView,
    meta: {
      info: false
    }
  },
  {
    path: '/payment',
    name: 'payment',
    component: PayView,
    meta: {
      info: false
    }
  },
  {
    path: '/instructions',
    name: 'instructions',
    component: InstructionsView,
    meta: {
      info: false
    }
  },
  {
    path: '/catalog',
    name: 'catalog',
    component: CatalogViewVue,
    meta: {
      info: false
    }
  },
  {
    path: '/subscriber/verify/success',
    name: 'access',
    component: AccessView,
    meta: {
      info: false
    }
  },
  {
    path: '/subscriber/verify/error',
    name: 'error',
    component: ErrorView,
    meta: {
      info: false
    }
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: NewPasswordViewVue,
    meta: {
      info: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      info: true
    }
  },
  {
    path: '/recovery',
    name: 'recovery',
    component: RecoveryViewVue,
    meta: {
      info: true
    }
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterViewVue,
    meta: {
      info: true
    }
  },
  {
    path: '/card/:slug',
    name: 'cardBySlug',
    component: CardView ,
    meta: {
      info: false
    }
  },
  {
    path: '/account',
    name: 'account',
    component: AccountView ,
    meta: {
      requiresAuth: true,
      info: false
    }
  },
  {
    path: '/pages/:id',
    name: 'pages',
    component: PagesView ,
    meta: {
      info: false
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("access_token")) {
      next();
    }
    else {
      toast.error('Вы не авторизованы')
    }
  }
  else {
    next();
  }
});

export default router
