<template>
    <div class="account container">
        <div class="account__left">
            <div class="account__left__block">
                <div class="account__left__block__title">
                    Личный кабинет 
                </div>
                <div class="account__left__block__info">
                    <div :class="{activeBlock: $store.state.openAcc === 'order'}" @click="$store.state.openAcc = 'order'">Текущие заказы</div>
                    <div :class="{activeBlock: $store.state.openAcc === 'basket'}" @click="$store.state.openAcc = 'basket'">Моя корзина</div>
                    <div :class="{activeBlock: $store.state.openAcc === 'deferred'}" @click="$store.state.openAcc = 'deferred'">Отложенные товары</div>
                    <div :class="{activeBlock: $store.state.openAcc === 'expected'}" @click="$store.state.openAcc = 'expected'">Ожидаемые товары</div>
                    <div :class="{activeBlock: $store.state.openAcc === 'archive'}" @click="$store.state.openAcc = 'archive'">Архив заказов</div>
                </div>
            </div>
            <div class="account__left__block">
                <div class="account__left__block__title1">
                    Личные данные
                </div>
                <div class="account__left__block__info">
                    <div :class="{activeBlock: $store.state.openAcc === 'email'}" @click="$store.state.openAcc = 'email'">E-mail рассылки</div>
                    <div :class="{activeBlock: $store.state.openAcc === 'data'}" @click="$store.state.openAcc = 'data'">Изменить данные</div>
                </div>
            </div>
        </div>
        <div class="account__right">
            <div class="mobile">
                <div class="mobilePersonal">
                    <div class="mobilePersonal__block">
                        <div class="mobilePersonal__block__title">
                            <div class="mobilePersonal__block__title__top" @click="openModal = !openModal">
                                <p>Личный кабинет</p>
                                <img src="@/assets/icons/select1.svg"/>
                            </div>
                            <div class="mobilePersonal__block__title__bottom" v-if="openModal == true">
                                <div @click="$store.state.openAcc = 'order'">Текущие заказы</div>
                                <div @click="$store.state.openAcc = 'basket'">Моя корзина</div>
                                <div @click="$store.state.openAcc = 'deferred'">Отложенные товары</div>
                                <div @click="$store.state.openAcc = 'expected'">Ожидаемые товары</div>
                                <div @click="$store.state.openAcc = 'archive'">Архив заказов</div>
                                <div @click="$store.state.openAcc = 'email'">E-mail рассылки</div>
                                <div @click="$store.state.openAcc = 'data'">Изменить данные</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="account__right__personal" v-if="$store.state.openAcc == 'personal'">
                <div class="account__right__personal__title">
                    <p>Личный кабинет</p>
                </div>
                <div class="account__right__personal__image">
                    <img :src="$store.state.user.data?.photo" class="account__right__personal__image__main"/>
                </div>
                <div class="account__right__personal__name">
                <p>{{ $store.state.user.data?.first_name }} {{ $store.state.user.data?.last_name }}</p>
                </div>
                <div class="account__right__personal__change">
                    <div class="account__right__personal__change__title">
                        <p style="cursor: pointer;" @click="$store.state.openAcc = 'data'">Изменить данные</p>
                    </div>
                    <div class="account__right__personal__change__blocks">
                        <div class="account__right__personal__change__blocks__block">
                            <p>{{$store.state.user.data?.first_name}}</p>
                        </div>
                        <div class="account__right__personal__change__blocks__block">
                            <p>{{$store.state.user.data?.last_name}}</p>
                        </div>
                        <div class="account__right__personal__change__blocks__block">
                            <p class="account__right__personal__change__blocks__block__title">E-mail:</p>
                            <p>{{$store.state.user.data?.email}}</p>
                        </div>
                        
                    </div>  
                </div>
            </div>
            <div class="accoun__right__favourites" v-if="$store.state.openAcc == 'favourites'">
                <FavouritesVue />
            </div>
            <div class="account__right__data" v-if="$store.state.openAcc == 'data'">
                <Bread :title1="'Личный кабинет'" :title2="'Изменить данные'"/>
                <div class="account__right__data__title">
                    <p>Личные данные</p>
                </div>
                <div class="account__right__data__ok" v-if="ok == true">
                    <img src="@/assets/icons/check.svg"/>
                    <p>Изменения сохранены</p>
                </div>
                <div class="account__right__data__info">
                    <input type="text" :placeholder="$store.state.user?.data?.first_name" v-model="v$.first_name.$model"/>
                    <template v-for="(error) of v$.first_name.$errors" :key="error">
                        <p class="errorValid">{{ error.$message }}</p>
                    </template>
                </div>
                <div class="account__right__data__info">
                    <input type="text" :placeholder="$store.state.user?.data?.last_name" v-model="v$.last_name.$model"/>
                    <template v-for="(error) of v$.last_name.$errors" :key="error">
                        <p class="errorValid">{{ error.$message }}</p>
                    </template>
                </div>
                <div class="account__right__data__info">
                    <input type="email" :placeholder="$store.state.user?.data?.email" v-model="v$.email.$model"/>
                    <template v-for="(error) of v$.email.$errors" :key="error">
                        <p class="errorValid">{{ error.$message }}</p>
                    </template>
                </div>
                <div class="account__right__data__photo">
                    <div class="account__right__data__photo__title">
                        <p>Изображение профиля</p>
                    </div>
                    <div class="account__right__data__photo__download">
                        <input type="file" ref="fileInput" @change="handleFileChange"/>
                    </div>
                </div>
                <!-- <div class="account__right__data__password">
                    <div class="account__right__data__password__block">
                        <p>Пароль</p>
                    </div>
                    <div class="account__right__data__password__input">
                        <input type="password" placeholder="Введите новый пароль" v-model="v$.password.$model" />
                        <template v-for="(error) of v$.password.$errors" :key="error">
                            <p class="errorValid">{{ error.$message }}</p>
                    </template>
                    </div>
                    <div class="account__right__data__password__input">
                        <input type="password" placeholder="Подтвердите пароль" v-model="v$.confirmPassword.$model"/>
                        <template v-for="(error) of v$.confirmPassword.$errors" :key="error">
                        <p class="errorValid">{{ error.$message }}</p>
                    </template>
                    </div>
                </div> -->
                <button class="account__right__data__save" @click="Change">Сохранить</button>
            </div>
            <div class="account__right__order" v-if="$store.state.openAcc == 'order'">
                <CurrentOrders />
            </div>
            <div class="account__right__basket" v-if="$store.state.openAcc == 'basket'">
                <Basket />
            </div>
            <div class="account__right__basket" v-if="$store.state.openAcc == 'deferred'">
                <DeferredVue />
            </div>
            <div class="account__right__basket" v-if="$store.state.openAcc == 'expected'">
                <ExpectedVue />
            </div>
            <div class="account__right__basket" v-if="$store.state.openAcc == 'archive'">
                <ArchiveVue />
            </div>
            <div class="account__right__basket" v-if="$store.state.openAcc == 'prophile'">
                <ProphileVue />
            </div>
            <div class="account__right__basket" v-if="$store.state.openAcc == 'email'">
                <Email />
            </div>
        </div>
    </div>
</template>
<script>
import { useVuelidate } from '@vuelidate/core'
import { mask } from "vue-the-mask";
import { required, minLength, helpers, email, sameAs  } from "@vuelidate/validators";
import Search from '@/components/Search.vue';
import Bread from '@/components/Bread.vue';
import CurrentOrders from '@/components/CurrentOrders.vue';
import Basket from '@/components/Basket.vue';
import DeferredVue from '@/components/Deferred.vue';
import ExpectedVue from '@/components/Expected.vue';
import ArchiveVue from '@/components/Archive.vue';
import ProphileVue from '@/components/Prophile.vue';
import Email from '@/components/Email.vue';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import FavouritesVue from '@/components/Favourites.vue';
export default {
components:{
    Search, Bread, CurrentOrders, Basket, DeferredVue, ExpectedVue, 
    ArchiveVue, ProphileVue, Email, FavouritesVue
  },
  directives: {
    mask
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data(){
    return{
        selectedFile: null,
        ok: false,
        first_name: '',
        last_name: '',
        email: '',
        file: '',
        openModal: false
    }
  },
  validations() {
    return {
        first_name: {
          minLength: helpers.withMessage('Слишком короткое имя', minLength(3)
          ),
      },
      last_name: {
        minLength: helpers.withMessage('Слишком короткое фамилия', minLength(3))
      },
      email: {
          email: helpers.withMessage('Некорректный email', email),
      },
    };
  },
  methods:{
    async Change() {
      this.v$.$validate();
      if(!this.v$.$invalid) {
        let data = new FormData() 
        data.append("email", this.email ? this.email : this.$store.state.user.data.email) 
        data.append("last_name", this.last_name ? this.last_name : this.$store.state.user?.data?.last_name) 
        data.append("first_name", this.first_name ? this.first_name : this.$store.state.user?.data?.first_name,) 
        data.append("photo", this.file) 
        this.$axios.post("user/profile",data, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
                })
        .then(res => {
            this.ok = true
        })
        .catch(err => {
        if (err?.response?.data?.message) {
            toast(err?.response?.data?.message);
          }
        })
      }
      else {
        toast("Ошибка валидации");
      }
    },
    handleFileChange() { 
        this.file = this.$refs.fileInput.files['0']; 
        console.log(this.file);
    },
  },
  watch: {
    '$store.state.openAcc': function(newVal) {
      // Установите openModal в false при изменении $store.state.openAcc
      this.openModal = false;
    },
  },
}
</script>
<style lang="scss" scoped>
.mobilePersonal{
 &__block{
    display: flex;
    flex-direction: column;
    &__title{
        position: relative;
        &__top{
            padding: 25px;
            background: #006495;
            color: white;
            font-size: 15px;
            border-radius: 10px;
            display: flex;
            gap: 10px;
            align-items: center;
        }
        &__bottom{
            position: absolute;
            top: 100%;
            display: flex;
            flex-direction: column;
            border: 1px solid #0000001A;
            box-shadow: 0px 4px 4px 0px #00000040;
            padding: 20px;
            gap: 20px;
            z-index: 999;
            background: white;
            border-radius: 10px;
        }
    }
 }
}
.errorValid {
  font-size: 12px !important;
  color: #FF2A62 !important;
}
.activeBlock{
    background: #006495;
    color: white;
}
    .account{
        display: flex;
        justify-content: space-between;
        padding-top: min(max(20px, calc(1.25rem + ((1vw - 7.68px) * 1.7361))), 40px);
        padding-bottom: min(max(80px, calc(5rem + ((1vw - 7.68px) * 6.5104))), 155px);
        color: #363636;
        &__left{
            width: 22.63%;
            height: 100%;
            box-shadow: 0px 4px 16px 0px #00000026;
            border-radius: 10px;
            font-size: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.6944))), 18px);
            @media(max-width:768px){
                display: none;
            }
            &__block{
                &__title{
                padding: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 1.8229))), 30px) min(max(8px, calc(0.5rem + ((1vw - 7.68px) * 1.3889))), 24px);
                background: #006495;
                color: white;
                border-radius: 10px 10px 0px 0px;
                }
                &__title1{
                    padding: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 1.8229))), 30px) min(max(8px, calc(0.5rem + ((1vw - 7.68px) * 1.3889))), 24px);
                    font-weight: 700;
                }
                &__info{
                    padding: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 1.8229))), 30px) min(max(8px, calc(0.5rem + ((1vw - 7.68px) * 1.3889))), 24px);
                display: flex;
                color: #4E4E4E;
                flex-direction: column;
                gap:  min(max(11px, calc(0.6875rem + ((1vw - 7.68px) * 0.5208))), 17px);
                div{
                    border: 1px solid #3636361A;
                    padding: min(max(4px, calc(0.25rem + ((1vw - 7.68px) * 0.3472))), 8px)  min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.6944))), 20px);
                    border-radius: 10px;
                    cursor: pointer;
                }
                }
            }
        }
        &__right{
            width: 74.07%;
            @media(max-width:768px){
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 20px;
            }
            &__data{
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 1.3021))), 30px);
            &__title{
                font-size: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.7813))), 24px);
                @media(max-width:768px){
                    font-size: 15px;
                }
            }
            &__ok{
                width: 27%;
                padding: min(max(4px, calc(0.25rem + ((1vw - 7.68px) * 0.3472))), 8px) min(max(7px, calc(0.4375rem + ((1vw - 7.68px) * 0.6944))), 15px);
                display: flex;
                gap: 10px;
                background: #3BE30061;
                border-radius: 5px;
                @media(max-width:768px){
                    width: 50%;
                    font-size: 14px;
                }
            }
            &__info{
                input{
                font-weight: 300;
                width: 27%;
                padding: min(max(8px, calc(0.5rem + ((1vw - 7.68px) * 0.6076))), 15px);
                border: 1px solid #00000026;
                border-radius: 5px;
                font-size: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.5208))), 18px);
                outline: none;
                @media(max-width:768px){
                    width: 70%;
                    font-size: 14px;
                }
                }
            }
            &__photo{
                display: flex;
                flex-direction: column;
                gap: 10px;
                &__title{
                    font-size:  min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.5208))), 18px);
                    @media(max-width:768px){
                        width: 100%;
                        font-size: 14px;
                    }
                }
                &__download{
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    color: #00000080;
                    font-size: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.5208))), 15px);
                    @media(max-width:768px){
                        font-size: 11px;
                    }
                    input{
                        font-size: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.5208))), 15px);
                        @media(max-width:768px){
                            font-size: 11px;
                        }
                    }
                    background: inherit;
                }
            }
            &__password{
                display: flex;
                flex-direction: column;
                gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.8681))), 20px);
                width: 27%;
                &__block{
                    width: 100%;
                    padding: min(max(4px, calc(0.25rem + ((1vw - 7.68px) * 0.2604))), 7px) min(max(7px, calc(0.4375rem + ((1vw - 7.68px) * 0.6944))), 15px);
                    font-size: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.5208))), 15px);
                    background: #00649524;
                    border-radius: 5px;
                }
                input{
                    width: 100%;
                    padding: min(max(8px, calc(0.5rem + ((1vw - 7.68px) * 0.6076))), 15px);
                    border: 1px solid #00000026;
                    border-radius: 5px;
                    outline: none;
                }
            }
            &__save{
                width: 17%;
                cursor: pointer;
                background: #006495;
                color: white;
                font-size: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.5208))), 15px);
                padding: min(max(6px, calc(0.375rem + ((1vw - 7.68px) * 0.3472))), 10px) 0px;
                border: none;
                border-radius: 5px;
                @media(max-width:768px){
                    width: 30%;
                    font-size: 11px;
                }
            }
            }
            &__personal{
                width: 100%;
                display: flex;
                flex-direction: column;
                gap:  min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 1.3021))), 30px);
                &__title{
                    color: #363636;
                    font-size:  min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.7813))), 24px);
                    @media(max-width:768px){
                        font-size: 15px;
                    }
                }
                &__image{
                    position: relative;
                    width: 150px;
                    height: 150px;
                    @media(max-width:768px){
                        width: 100px;
                        height: 100px;
                    }
                    &__main{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 50%;
                    }
                }
                .plus1{
                    position: absolute;
                    bottom: 0;
                    right: 0;
                }
                &__name{
                    font-size: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.6944))), 20px);
                    color: black;
                    @media(max-width:768px){
                        font-size: 15px;
                    }
                }
                &__change{
                    display: flex;
                    flex-direction: column;
                    gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 1.3021))), 30px);
                    &__title{
                        color: #006495;
                        @media(max-width:768px){
                            font-size: 14px;
                        }
                    }
                    &__blocks{
                    width: 62.76%;
                    display: flex;
                    flex-wrap: wrap;
                    gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 1.3021))), 30px);
                    @media(max-width:768px){
                        width: 75%;
                        font-size: 14px;
                    }
                    &__block{
                        width: 48%;
                        border: 1px solid #36363626;
                        padding: min(max(4px, calc(0.25rem + ((1vw - 7.68px) * 0.2604))), 7px) min(max(7px, calc(0.4375rem + ((1vw - 7.68px) * 0.6944))), 15px);
                        display: flex;
                        gap: 10px;
                        border-radius: 10px;
                        font-size: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.5208))), 18px);
                        &__title{
                            color: #36363680;
                            font-size: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.5208))), 18px);
                        }
                        @media(max-width:768px){
                            width: 100%;
                            font-size: 14px;
                        }
                        }
                }
                }
            }
        }
    }
</style>