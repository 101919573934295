<template>
    <div class="in" @click="$router.push({ name: 'cardBySlug', params: { slug: item.slug } })">
        <div class="in__img">
            <img :src="item.image" alt="Card Image" />
        </div>
        <p class="in__title">{{ item.title }}</p>
        <div class="in__block">
            <p>Артикул: {{ item.vendor_code }}</p>
            <div class="in__block__status">
            <p>{{ item.status_name }}</p>
            <img src="@/assets/icons/ok.svg" alt="Status Icon" v-if="item.status = 0"/>
            <img src="@/assets/icons/yes.svg" alt="Status Icon" v-else/>
            </div>
            <p class="in__block__price">от {{ item.price }}</p>
            <div class="in__block__bot" @click.stop>
                <img src="@/assets/icons/heart3.svg" @click="addFavour(item)" :class="{newHeart: isLiked}" class="heart"/>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    props: ['item'],
    methods: {
        ...mapActions(['addFavour']),
    },
    computed: {
        ...mapGetters(['getLiked']),
        isLiked() {
            return this.getLiked.some(card => card.id === this.item.id);
        },
    }
}
</script>

<style lang="scss" scoped>
.heart{
    &:hover{
        transform: scale(1.1);
        transition: all 0.2s ease;
        cursor: pointer;
    }
}
.newHeart{
    filter: brightness(0) saturate(100%) invert(17%) sepia(78%) saturate(3656%) hue-rotate(336deg) brightness(90%) contrast(102%);
    transition: all 0.5s ease;
    cursor: pointer;
}
    .in {
        height: 100%;
        cursor: pointer;
        border: 1px solid #0000001A;
        border-radius: 10px;
        padding:  min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 1.1285))), 25px);
        display: flex;
        flex-direction: column;
        gap: min(max(7px, calc(0.4375rem + ((1vw - 7.68px) * 0.434))), 12px);
        font-size: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
        color: #00000080;
        box-shadow: 0px 4px 16px 0px #4098FF26;
        transition: all 0.5s ease;
        width:  min(max(150px, calc(9.375rem + ((1vw - 7.68px) * 8.6806))), 250px);
        @media(max-width:768px){
                transition: none;
                font-size: 12px;
                padding: 12px;
                gap: 8px;
                width: 97%;
                height: fit-content;
            }
        &:hover{
            transform: scale(1.05);
            @media(max-width:768px){
                transform: none;
            }
        }
        &__img {
            width: 100%;
            img {
                width: 100%;
                aspect-ratio: 1;
                object-fit: cover; /* Обрезать изображение, чтобы оно заполняло контейнер */
                border-radius: 8px; /* Округление углов изображения */
            }
        }

        &__title {
            width: 100%;
            color: #232F3E;
            font-size: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.5208))), 18px); /* Установите фиксированный размер для заголовка */
            margin: 8px 0; /* Добавьте отступы для выравнивания */
            flex-grow: 1;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 1.2em; /* Adjust based on your line height */
            max-height: 3.6em; /* 3 * line-height */
            @media(max-width:768px){
                font-size: 14px;
            }
        }
        &__block{
            margin-top: auto;
            display: flex;
            flex-direction: column;
            gap: min(max(7px, calc(0.4375rem + ((1vw - 7.68px) * 0.434))), 12px);
            @media(max-width:768px){
                gap: 8px;
            }
            &__status {
            display: flex;
            align-items: center;
            gap: 10px;
            }
            &__price {
                font-size: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.5208))), 18px) !important;
                color: black !important;
                @media(max-width:768px){
                    font-size: 14px !important;
                }
            }
            &__bot {
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
                img{
                    width: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.8681))), 20px);
                    height: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.8681))), 20px);
                    @media(max-width:768px){
                            width: 20px;
                            height: 20px;
                    }
                }
            }
        }
    }
</style>