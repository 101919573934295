<template>
    <div class="product">
        <div class="arrows arrowLeft">
            <img src="@/assets/icons/arrow1.svg" class="arrowLeft"/>
        </div>
        <swiper 
            class="product-swiper"
            :key="name"
            :breakpoints="{ 
                    1500:{
                        slidesPerView: 5, 
                        spaceBetween: 90
                    },
                    1200:{
                        slidesPerView: 5, 
                        spaceBetween: 50
                    },  
                    1000:{
                        slidesPerView: 5, 
                        spaceBetween: 30
                    },
                    768: {
                        slidesPerView: 5, 
                        spaceBetween: 20
                    },
                    0: { 
                        slidesPerView: 1.8, 
                        spaceBetween: 20 
                    } 
                }" 
            :lazy="true"
            :navigation="{ 
                prevEl: '.arrowLeft', 
                nextEl: '.arrowRight' 
            }" 
            >   <swiper-slide class="swiper1" v-for="(item) in productsArray" :key="item">
                <CardProduct :item="item"/>
            </swiper-slide>
        </swiper>
        <div class="arrows arrowRight">
            <img src="@/assets/icons/arrow2.svg" class="arrowRight"/>
        </div>
    </div>
</template>
<script>
import { Swiper, SwiperSlide,} from "swiper/vue"; 
import "swiper/css"; 
import "swiper/css/pagination"; 
import "swiper/css/navigation";  
import CardProduct from "./CardProduct.vue";
import SwiperCore, { A11y, Autoplay } from "swiper"; 
import { Navigation, Pagination } from 'swiper'; 
import "swiper/swiper.min.css"; 
SwiperCore.use([Navigation,Pagination, A11y, Autoplay])

export default {
    components:{
        Swiper, SwiperSlide, CardProduct
    },
    props: {
        classname: String, // Пример уже существующего пропса типа String
        productsArray: Array // Новый пропс для массива продуктов
    },
    data(){
        return{
            products:[
            {
                img: 'product1',
                title: 'Лайнер для бассейна Aquaviva Danube',
                articul: '2424',
                price: '126 500Т.'
            },
            {
                img: 'product2',
                title: 'Лайнер для бассейна Aquaviva Danube',
                articul: '2424',
                price: '126 500Т.'
            },
            {
                img: 'product3',
                title: 'Лайнер для бассейна Aquaviva Danube',
                articul: '2424',
                price: '126 500Т.'
            },
            {
                img: 'product4',
                title: 'Лайнер для бассейна Aquaviva Danube',
                articul: '2424',
                price: '126 500Т.'
            },
            {
                img: 'product5',
                title: 'Лайнер для бассейна Aquaviva Danube',
                articul: '2424',
                price: '126 500Т.'
            },
            {
                img: 'product6',
                title: 'Лайнер для бассейна Aquaviva Danube',
                articul: '2424',
                price: '126 500Т.'
            },
            ]
        }
    }
}
</script>
<style lang="scss" scoped>
.product-swiper{
    padding: 25px;
}
.product{
    position: relative;
}
.arrows {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 50px; /* Задайте нужную ширину стрелок */
    height: 50px; /* Задайте нужную высоту стрелок */
    cursor: pointer;
}

.arrowLeft {
    left: -15px;
    z-index:999 !important;
}

.arrowRight {
    right: -35px;
    z-index:999 !important;
}
    .swiper1{
        height: auto;
        z-index: 1;
    }
</style>