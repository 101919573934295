<template>
    <div class="home__block2 container">
      <div class="home__block2__left">
        <img src="@/assets/icons/list.svg"/>
        <p>Каталог товаров</p>
      </div>
      <div class="home__block2__right">
        <div class="home__block2__right__link">
          <div class="home__block2__right__link__left">
            <p>Перейти на сайт</p>
            <img src="@/assets/icons/arrow.svg"/>
          </div> 
          <div class="home__block2__right__link__right">
            <p>https://aquapolis.ru/</p>
          </div>
        </div>
        <input type="search" placeholder="Поиск по товарам, брендам, категориям "/> 
        <div class="home__block2__right__end">
          <div class="home__block2__right__end__info">
            <img src="@/assets/icons/message1.svg"/>
            <p style="text-decoration: underline;">info@info.kz</p>
          </div>
          <div class="home__block2__right__end__info">
            <img src="@/assets/icons/heart.svg"/>
            <p>Избранное</p>
          </div>
          <div class="home__block2__right__end__info">
            <img src="@/assets/icons/basket.svg"/>
            <p>Корзина</p>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
export default {
    
}
</script>
<style lang="scss" scoped>
    .home{
  &__block2{
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    align-items: center;
    padding-top: 40px;
    &__left{
      width: 20%;
      background: #006495;
      padding: 11px 21px;
      height: fit-content;
      border-radius: 5px;
      display: flex;
      gap: 33px;
      color: white;
    }
    &__right{
      display: flex;
      width: 75%;
      justify-content: space-between;
      &__link{
        width: 31%;
        display: flex;
        border: 1px solid #00649580;
      border-radius: 5px;
        &__left{
          background: #006495;
          padding: 11px 21px;
          display: flex;
          gap: 20px;
          align-items: center;
          color: white;
          img{
            width: 12px;
            height: 12px;
          }
        }
        &__right{
          padding: 11px 45px;
          color: #006495;
          text-decoration: underline;
        }
      }
      input{
        border: 1px solid #00649580;
        width: 31%;
        padding: 11px 20px;
        border-radius: 5px;
      }
      &__end{
        display: flex;
        gap: 20px;
        color: #006495;
        &__info{
          display: flex;
          gap: 10px;
          align-items: center;
        }
      }
    }
  }
}
</style>