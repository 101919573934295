<template>
    <div class="deferred">
      <Bread :title1="'Личный кабинет'" :title2="'Отложенные товары '"/>  
        <div class="deferred__title">
            <p>Отложенные товары </p>
        </div>
        <div class="deferred__products" v-if="newDeferred.length > 0">
            <div class="deferred__products__in" v-for="item in newDeferred" :key="item">
                <img :src="item.image" class="deferred__products__in__img" />
                <p class="deferred_products__in__title">{{ item.product_title }}</p>
                <p>Артикул: {{ item.vendor_code }}</p>
                <div class="deferred__products__in__1">
                    <p>В наличии</p>
                    <img src="@/assets/icons/ok.svg" />
                </div>
                <p class="deferred__products__in__price">от {{ item.price }}Т</p>
                <div class="deferred__products__in__bot">
                    <p>Товар отложен</p>
                </div>
            </div>
        </div> 
        <div class="deferred__not" v-else>
            <p>У вас нет отложенных товаров</p>
        </div>
    </div>
</template>
<script>
import Bread from './Bread.vue';
import CardProduct from './CardProduct.vue';
export default {
    data(){
        return{
            newDeferred: false,
             products: [
        {
          img: 'product1',
          title: 'Лайнер для бассейна Aquaviva Danube',
          articul: '2424',
          price: '126 500Т.'
        },
        {
          img: 'product2',
          title: 'Лайнер для бассейна Aquaviva Danube',
          articul: '2424',
          price: '126 500Т.'
        },
        {
          img: 'product3',
          title: 'Лайнер для бассейна Aquaviva Danube',
          articul: '2424',
          price: '126 500Т.'
        },
        {
          img: 'product4',
          title: 'Лайнер для бассейна Aquaviva Danube',
          articul: '2424',
          price: '126 500Т.'
        },
      ],
        }
    },
    components: {
        Bread, CardProduct
    },
    async created() {
          this.getPage()    
      },
      methods: {
        async getPage() {    
              await this.$axios.get(`orders/deferred`, {
                headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
              })
              .then(res => {
                  this.newDeferred = res.data.orderProducts.data
              })
              .catch(err => {
              })        
          },
      },
}
</script>
<style lang="scss" scoped>
    .deferred{
        display: flex;
        flex-direction: column;
        gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 1.3021))), 30px);
        &__not{
          font-size: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.5208))), 18px);
          @media(max-width:768px){
              font-size: 14px;
          }
        }
        &__title{
            color: #363636;
            font-size: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.7813))), 24px);
            @media(max-width:768px){
                font-size: 15px;
            }
        }
        &__products{
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(min(max(150px, calc(9.375rem + ((1vw - 7.68px) * 8.6806))), 250px), 1fr));
      gap: min(max(30px, calc(1.875rem + ((1vw - 7.68px) * 2.6042))), 60px);
      row-gap: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);
          &__in{
              border: 1px solid #0000001A;
              border-radius: 10px;
              padding: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 1.0417))), 24px);
              display: flex;
              flex-direction: column;
              gap: min(max(7px, calc(0.4375rem + ((1vw - 7.68px) * 0.434))), 12px);
              font-size: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.5208))), 15px);
              @media(max-width:768px){
                  font-size: 13px;
              }
              color: #00000080;
              cursor: pointer;
              transition: all 0.5s ease;
              &:hover{
                transform: scale(1.05);
              }
              &__count{
                display: flex;
                justify-content: space-between;
                align-items: center;
                div{
                  border: 1px solid #00000026;
                  border-radius: 5px;
                }
                &__plus{
                  padding: min(max(3px, calc(0.1875rem + ((1vw - 7.68px) * 0.2604))), 6px) min(max(5px, calc(0.3125rem + ((1vw - 7.68px) * 0.3472))), 9px);
                }
                &__minus{
                  padding: min(max(3px, calc(0.1875rem + ((1vw - 7.68px) * 0.2604))), 6px) min(max(5px, calc(0.3125rem + ((1vw - 7.68px) * 0.3472))), 9px);
                }
                &__number{
                  width: 50%;
                  padding: 5px 0px;
                  display: flex;
                  justify-content: center;
                }
              }
              &__img{
                  width: 100%;
                  aspect-ratio: 1;
              }
              &__title{
                  color: #232F3E;
                  flex-grow: 1;
              }
              &__1{
                  display: flex;
                  gap: 10px;
              }
              &__price{
                  font-size: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.5208))), 18px) !important;
                  color: black !important;
                  @media(max-width:768px){
                    font-size: 13px !important;
                }
              }
              &__bot{
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  color: red;
              }
          }
      }
    }
</style>    