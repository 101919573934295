<template>
    <div class="access container">
        <Table />
        <div class="access__right" v-if="this.$route.query.error_type == '0'">
            <p>Ваша запись не найдена</p>
        </div>
        <div class="access__right" v-if="this.$route.query.error_type == '1'">
            <p>{{ decodedMessage }}</p> <!-- Fix: Use the correct property name here -->
        </div>
    </div>
</template>

<script>
import Table from '@/components/Table.vue';
export default {
    data() {
        return {
            decodedMessage: '' // Fix: Use the correct property name here
        };
    },
    components: {
        Table
    },
    mounted() {
        this.decodeMessage();
    },
    methods: {
        decodeMessage() {
            const urlParams = new URLSearchParams(window.location.search);
            const encodedMessage = urlParams.get('message');

            if (encodedMessage) {
                const decodedMessage = decodeURIComponent(atob(encodedMessage));
                console.log(decodedMessage);

                // Now you can use the decoded message as needed in your frontend logic
                // For example, you can set it to a data property for use in your template
                this.decodedMessage = decodedMessage; // Fix: Use the correct property name here
            }
        }
    },
};
</script>
<style lang="scss" scoped>
    .access{
        display: flex;
        justify-content: space-between;
        padding-top: 25px;
        padding-bottom: 150px;
        &__right{
            width: 74.07%;
            display: flex;
            flex-direction: column;
            gap: 25px;
        }
    }
</style>