<template>
  <PayView v-if="$store.state.openPay == true" :selectPayer="selectedPayerType" :selectDeliever="selectedDeliveryType"/>
  <div class="basket" v-if="$store.state.openBasket == 'basket'">
    <Bread :title1="'Личный кабинет'" :title2="'Моя корзина'"/> 
      <div class="basket__title">
          <p>Моя корзина</p>
      </div>
      <div class="basket__sum">
        <div class="basket__sum__price">
          <p>Итого: {{finalPrice}}Т.</p>
        </div>
        <button @click="$store.state.openBasket = 'placing', openPlacing()" style="cursor: pointer;">Перейти к оплате</button>
      </div>
      <div class="basket__products" v-if="getBasket.length > 0">
        <div class="basket__products__in" v-for="item in getBasket" :key="item">
          <img :src="item.image" class="basket__products__in__img" />
          <p class="basket__products__in__title">{{ item.title }}</p>
          <p>Артикул: {{ item.vendor_code }}</p>
          <div class="basket__products__in__1">
            <p>В наличии</p>
            <img src="@/assets/icons/ok.svg" />
          </div>
          <p class="basket__products__in__price">от {{ item.price }}Т</p>
          <div class="basket__products__in__count">
            <div class="basket__products__in__count__minus"  @click="counterMinus(item)" style="cursor: pointer;">
              <img src="@/assets/icons/minus1.svg"/>
            </div>
            <div class="basket__products__in__count__number">
              <p>{{ item.counter }}</p>
            </div>
            <div class="basket__products__in__count__plus" @click="counterPlus(item)" style="cursor: pointer;">
              <img src="@/assets/icons/plus2.svg"/>
            </div>
          </div>
          <div class="basket__products__in__bot">
            <p style="cursor: pointer;" @click="addBasket(item)">Удалить</p>
          </div>
        </div>
      </div>
      <div v-else class="basket__products__not">
        <p>У вас нет товаров в корзине</p>
      </div>
  </div>
  <div class="placing" v-if="$store.state.openBasket == 'placing' && getBasket.length > 0">
    <Bread :title1="'Личный кабинет'" :title2="'Моя корзина'" :title3="'Оформление заказа'"/>  
    <div class="placing__title">
      <p>Оформление заказа</p>
      <p style="color: rgb(88, 118, 214); cursor: pointer;" @click="$store.state.openBasket = 'basket'">Назад</p>
    </div>
    <div class="placing__choose">
        <div class="placing__choose__type">
          <div class="placing__choose__type__title">
            <p>Тип плательщика</p>
          </div>
          <div class="placing__choose__type__inputs">
            <div class="placing__choose__type__inputs__input">
              <input type="radio" id="0" name="payerType" v-model="selectedPayerType" value="0"/>
              <p>Физическое лицо</p>
            </div>
            <div class="placing__choose__type__inputs__input">
              <input type="radio" id="1" name="payerType" v-model="selectedPayerType" value="1"/>
              <p>Юридическое лицо</p>
            </div>
          </div>
        </div>
        <div class="placing__choose__type">
          <div class="placing__choose__type__title">
            <p>Доставка</p>
          </div>
          <div class="placing__choose__type__inputs">
            <div class="placing__choose__type__inputs__input">
              <input type="radio" id="address" name="deliveryType" v-model="selectedDeliveryType" value="0"/>
              <p>До адресса</p>
            </div>
            <div class="placing__choose__type__inputs__input">
              <input type="radio" id="pickup" name="deliveryType" v-model="selectedDeliveryType" value="1"/>
              <p>Самовывоз</p>
            </div>
          </div>
        </div>
        <button style="cursor: pointer;" @click="proceedToPayment">Далее</button>
    </div>
    <div class="placing__products">
      <div class="placing__products__in" v-for="item in getBasket" :key="item">
        <div class="placing__products__in__image">
          <img :src="item.image"/>
        </div>
        <div class="placing__products__in__desc">
          <p>{{ item.title }}</p>
          <div class="placing__products__in__desc__articul">
            <p>Артикул : {{ item.vendor_code }}</p>
          </div>
        </div>
        <div class="placing__products__in__count">
          {{ item.counter }} шт
        </div>
        <div class="placing__products__in__price">
          <p>{{ item.price }}Т.</p>
        </div>
      </div>
    </div>
  </div>
  <div class="pay" v-if="$store.state.openBasket == 'pay'">
    <Bread :title1="'Личный кабинет'" :title2="'Моя корзина'" :title3="'Оплата'"/>  
    <div class="pay__title">
      <p>Оплата</p>
      <p style="font-size: 14px; color: rgb(88, 118, 214); cursor: pointer;" @click="$store.state.openBasket = 'placing'">Назад</p>
    </div>
    <div class="pay__sub"> 
      <p>Покупатель</p>
    </div>
    <div class="pay__block">
      <div class="pay__block__left">
        <div class="pay__block__left__input">
          <input type="text" :placeholder="$store.state.user.data.first_name" disabled/>
        </div>
        <div class="pay__block__left__input">
          <input type="text" :placeholder="$store.state.user.data.last_name" disabled/>
        </div>
        <div class="pay__block__left__input">
          <input type="text" :placeholder="$store.state.user.data.email" disabled/>
        </div>
        <div class="pay__block__left__input" v-if="selectedDeliveryType == '0'">
          <input type="text" placeholder="Адрес доставки:" v-model="v$.address.$model"/>
          <template v-for="(error) of v$.address.$errors" :key="error">
             <p class="errorValid">{{ error.$message }}</p>
          </template>
        </div>
        <div class="pay__block__left__comment">
          <textarea placeholder="Комментарий к заказу:" v-model="comment"></textarea>
        </div>
        <div class="pay__block__left__check">
          <div class="pay__block__left__check__left">
            <input type="checkbox" v-model="v$.check.$model"/>
            <template v-for="(error) of v$.check.$errors" :key="error">
              <p class="errorValid">{{ error.$message }}</p>
            </template>
          </div>
          <div class="pay__block__left__check__right">
            <p>При отправке данной формы Вы подтверждаете свою дееспособность
               <br/>и согласие на <span style="color: #006495; text-decoration: underline;">обработку персональных данных</span></p>
          </div>
        </div>
        <button style="cursor: pointer;" v-if="selectedDeliveryType == '0'" @click="sendData()">Оформить заказ</button>
        <button style="cursor: pointer;" v-if="selectedDeliveryType == '1'" @click="sendData1()">Оформить заказ</button>
      </div>
      <div class="pay__block__right">
        <div class="pay__block__right__title">
          <p>Выберите способ оплаты</p>
        </div>
        <div class="pay__block__right__choose">
          <div class="pay__block__right__choose__1">
            <input type="radio" id="0" name="method" v-model="selectedMethod" value="0"/>
            <div class="pay__block__right__choose__1__in">
               <img src="@/assets/icons/visa.svg"/>
               <p>Банковсвкие карты</p>
            </div>
          </div>
          <div class="pay__block__right__choose__1" v-if="selectedDeliveryType == '0'">
            <input type="radio" id="1" name="method" v-model="selectedMethod" value="1"/>
            <div class="pay__block__right__choose__1__in">
               <img src="@/assets/icons/card.svg"/>
               <p>Наличные курьеру</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="issued" v-if="$store.state.openBasket == 'issued'">        
    <Bread :title1="'Личный кабинет'" :title2="'Моя корзина'" :title3="'Оплата'"/>  
    <div class="issued__title">
      <p>Заказ оформлен</p>
    </div>
    <div class="issued__sub">
      <p>Ваш заказ № 123 от 12.12.2023 успешно создан.</p>
    </div>
    <div class="issued__block">
      <div class="issued__block__number">
        <p>Номер вашей оплаты: № 123</p>
      </div>
      <div class="issued__block__link">
        <p>Вы можете следить за выполнением своего заказа в</p>
        <p style="color: #006495; text-decoration: underline; cursor: pointer;">Персональном разделе сайта</p>
      </div>
      <div class="issued__block__visa">
        <p>Оплата заказа <span style="color: #006495;">VISA MASTERCARD</span></p>
      </div>
      <div class="issued__block__image">
        <img src="@/assets/icons/visamini.svg"/>
      </div>
      <div class="issued__block__price">
        <p>Сумма к оплате: {{ finalPrice }}Т. </p>
      </div>
      <button style="cursor: pointer;" @click="FETCH_PAY()">Оплатить</button>
      <div class="issued__block__message">
        <p>Вы будете перенаправлены на страницу оплаты</p>
      </div>
    </div>
  </div>
  <div class="issued2" v-if="$store.state.openBasket == 'issued2'">
    <Bread :title1="'Личный кабинет'" :title2="'Моя корзина'" :title3="'Оплата'"/>
    <div class="issued2__title">
      <p>Оформление заказа</p>
    </div> 
    <div class="issued2__sub">
      <p>Ваша заявка успешно принята.</p>
    </div>
    <div class="issued2__text">
      <p>Вы можете следить за выполнением своего заказа в</p>
      <p style="color: #006495; text-decoration: underline;">Персональном разделе сайта</p>
    </div>
  </div>
</template>
<script>
import Bread from './Bread.vue';
import PayView from '@/components/Pay.vue';
import CardProduct from './CardProduct.vue';
import { useVuelidate } from '@vuelidate/core'
import { required, minLength, email, helpers, numeric, maxLength } from '@vuelidate/validators'
import { toast } from 'vue3-toastify';
import { mapGetters, mapActions } from 'vuex';
import axios from "axios";
import 'vue3-toastify/dist/index.css';
export default {
  setup () {
        return { 
            v$: useVuelidate() 
        }
    },
  data(){
      return{
        selectedPayerType: '0',
        newPay: {},
        selectedDeliveryType: '0',
        simplifiedBasket: [],
        selectedMethod: '',
        check: '',
        address: '',
        comment: '',
        products: [
      {
        img: 'product1',
        title: 'Лайнер для бассейна Aquaviva Danube',
        articul: '2424',
        price: '126 500Т.'
      },
      {
        img: 'product2',
        title: 'Лайнер для бассейна Aquaviva Danube',
        articul: '2424',
        price: '126 500Т.'
      },
      {
        img: 'product3',
        title: 'Лайнер для бассейна Aquaviva Danube',
        articul: '2424',
        price: '126 500Т.'
      },
      {
        img: 'product4',
        title: 'Лайнер для бассейна Aquaviva Danube',
        articul: '2424',
        price: '126 500Т.'
      },
    ],
      }
  },
  components: {
      Bread,PayView, CardProduct
  },
  computed: {
    ...mapGetters(['getBasket']),
    finalPrice() {
          let sum = 0
          this.getBasket.forEach(element => {
              sum += (element.price * element.counter)
          })
          return sum;
      }
  },
  validations() {
    return {
        address: {
                required: helpers.withMessage('Обязательное поле', required),
            },
        check: {
                required: helpers.withMessage('Обязательное поле', required),
            },
        }
    },
  methods: {
    ...mapActions(['addBasket']),
    counterPlus(item) {
      item.counter += 1; // Увеличиваем значение на 1 при нажатии на плюс
    },
    counterMinus(item) {
      if (item.counter > 1) {
        item.counter -= 1; // Уменьшаем значение на 1 при нажатии на минус, если значение больше 0
      }
      else {
        toast.error('Миниальное значение')
      }
    },
    FETCH_PAY(){
          let auth = this.newPay.token
          let invoiceId = this.newPay.invoice_id
          let amount = this.newPay.amount
          halyk.showPaymentWidget(this.createPaymentObject(auth, invoiceId, amount), (res) => {if(res.success){this.isEnterBalance = false}});
    },
    createPaymentObject(auth, invoiceId, amount) {
      let paymentObject = {
        invoiceId: invoiceId,
        backLink: "",
        failureBackLink: "",
        postLink: "https://back-hayward.mydev.kz/api/V1/orders/payment-success",
        failurePostLink: "https://back-hayward.mydev.kz/api/V1/orders/payment-failed",
        language: this.lang === 'ru',
        description: "Оплата товара",
        accountId: "test",
        terminal: "67e34d63-102f-4bd1-898e-370781d0074d",
        amount: amount,
        currency: "KZT",
        // data: '{"statement":{"name":"Arman     Ali","invoiceID":"80000016"}}',
        cardSave: false, //Параметр должен передаваться как Boolean
      };
      paymentObject.auth = auth;
      return paymentObject;
    },
    async sendData1(){
      if(!this.selectedMethod){
        toast.error('Выберите тип оплаты')
      }
      else{
        if(this.comment){
          const back = {
              user_type: this.selectedPayerType,
              delivery_type: this.selectedDeliveryType,
              first_name: this.$store.state.user.data.first_name,
              last_name: this.$store.state.user.data.last_name,
              email: this.$store.state.user.data.email,
              address: '',
              message: this.comment,
              payment_method: this.selectedMethod,
              orderProducts: this.simplifiedBasket
            };
            try {
                const response = await axios.post("https://back-hayward.mydev.kz/api/V1/orders/create", back,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
                });
                if (response.status === 201) {
                    // Добавить новую группу в массив после успешного ответа
                    toast('Успешно отправлено')
                    this.comment = '';
                    this.$store.state.openBasket = 'issued';
                } else {
                  toast(err?.response?.data?.message)
                }
            } catch (error) {
                console.error(error);
            }
        }
        else {
          this.v$.$touch();
        }
      }
    },
    openPlacing(){
      if(this.getBasket.length === 0){
        toast.error('В корзине нет товаров')
        this.$store.state.openBasket = 'basket'
      }
    },
    async sendData(){
      if(!this.selectedMethod){
        toast.error('Выберите тип оплаты')
      }
      else{
        if(!this.v$.$invalid){
          const back = {
              user_type: this.selectedPayerType,
              delivery_type: this.selectedDeliveryType,
              first_name: this.$store.state.user.data.first_name,
              last_name: this.$store.state.user.data.last_name,
              email: this.$store.state.user.data.email,
              address: this.address,
              message: this.comment,
              payment_method: this.selectedMethod,
              orderProducts: this.simplifiedBasket
            };
            try {
                const response = await axios.post("https://back-hayward.mydev.kz/api/V1/orders/create", back,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
                });
                if (response.status === 201) {
                    // Добавить новую группу в массив после успешного ответа
                    toast('Успешно отправлено')
                    this.address = '';
                    this.comment = '';
                    this.$store.state.openBasket = 'issued';
                    this.newPay = response.data.data
                } else {
                  toast(err?.response?.data?.message)
                }
            } catch (error) {
                console.error(error);
            }
        }
        else {
          this.v$.$touch();
        }
      }
    },
    proceedToPayment(){
      if (!this.selectedPayerType && !this.selectedDeliveryType) {
        toast.error('Выберите тип плательщика и тип доставки')
      }
      else if (!this.selectedPayerType) {
        toast.error('Выберите тип плательщика');
      } 
      else if (!this.selectedDeliveryType) {
        toast.error('Выберите тип плательщика');
      } 
      else{
        if (this.selectedPayerType === '0') {
          // Действия для физического лица
          this.$store.state.openBasket = 'pay';
          // Здесь можете добавить другие действия для физического лица
        } 
        else if (this.selectedPayerType === '1'){
          this.$store.state.openPay = true;
        }
      }
    }
  },
  mounted() {
    // Создание упрощенного массива при монтировании компонента
    this.simplifiedBasket = this.getBasket.map(item => ({
      id: item.id,
      quantity: item.counter
    }));
  }

}
</script>
<style lang="scss" scoped>
.errorValid{
  color: red;
  font-size: 10px;
}
.issued2{
display: flex;
flex-direction: column;
gap: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 1.1285))), 25px);
&__title{
  font-size: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.7813))), 24px);
  @media(max-width:768px){
    font-size: 15px;
  }
}
&__sub{
  box-shadow: 0px 4px 16px 0px #4098FF26;
  color: white;
  border: 1px solid #36363626;
  border-radius: 5px;
  background: #039E0080;
  font-size:  min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.5208))), 18px);
  padding: min(max(6px, calc(0.375rem + ((1vw - 7.68px) * 0.3472))), 10px) min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 1.1285))), 25px);
  @media(max-width:768px){
    font-size: 13px;
  }
}
&__text{
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size:  min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.5208))), 18px);
  @media(max-width:768px){
    font-size: 13px;
  }
}
}
.issued{
display: flex;
flex-direction: column;
gap:  min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 1.3021))), 30px);
&__title{
  font-size: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.7813))), 24px);
  @media(max-width:768px){
    font-size: 15px;
  }
}
&__sub{
  box-shadow: 0px 4px 16px 0px #4098FF26;
  color: white;
  border: 1px solid #36363626;
  border-radius: 5px;
  background: #039E0080;
  padding: min(max(6px, calc(0.375rem + ((1vw - 7.68px) * 0.3472))), 10px) min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 1.1285))), 25px);
  font-size: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.5208))), 18px);
  @media(max-width:768px){
    font-size: 13px;
  }
} 
&__block{
  border: 1px solid #36363626;
  width: 60%;
  padding: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 1.1285))), 25px);
  display: flex;
  flex-direction: column;
  gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.8681))), 20px);
  border-radius: 5px;
  font-size: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.5208))), 18px);
  @media(max-width:768px){
    font-size: 13px;
    width: 100%;
  }
  &__link{
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  button{
    width: 30%;
      background: #006495;
      border: none;
      color: white;
      border-radius: 5px;
      padding:  min(max(7px, calc(0.4375rem + ((1vw - 7.68px) * 0.434))), 12px) 0px;
      font-size: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.5208))), 18px);
      @media(max-width:768px){
        font-size: 13px;
      }
  }
  &__message{
    color: #00000080;
  }
}
}
.pay{
display: flex;
flex-direction: column;
gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 1.3021))), 30px);
&__title{
  font-size: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.7813))), 24px);
  display: flex;
  justify-content: space-between;
}
&__sub{
  box-shadow: 0px 4px 16px 0px #4098FF26;
  color: white;
  border: 1px solid #36363626;
  border-radius: 5px;
  background: #039E0080;
  padding: min(max(6px, calc(0.375rem + ((1vw - 7.68px) * 0.3472))), 10px) min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 1.1285))), 25px);
  font-size: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.7813))), 24px);
}  
&__block{
  display: flex;
  @media(max-width:768px){
    flex-direction: column;
    gap: 20px;
  }
  &__left{
    width: 42%;
    display: flex;
    flex-direction: column;
    gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.8681))), 20px);
    @media(max-width:768px){
      width: 100%;
    }
    &__input{
      width: 75%;
      input{
        width: 100%;
        border: 1px solid #36363626;
        padding: min(max(6px, calc(0.375rem + ((1vw - 7.68px) * 0.3472))), 10px) min(max(8px, calc(0.5rem + ((1vw - 7.68px) * 0.6076))), 15px);
        outline: none;
        font-size: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.5208))), 15px);
        @media(max-width:768px){
          font-size: 13px;
        }
      }
    }
    &__comment{
      width: 75%;
      textarea{
        width: 100%;
        border: 1px solid #36363626;
        padding: min(max(6px, calc(0.375rem + ((1vw - 7.68px) * 0.3472))), 10px) min(max(8px, calc(0.5rem + ((1vw - 7.68px) * 0.6076))), 15px);
        height: 100px;
        outline: none;
        resize: none;
        font-size: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.5208))), 15px);
        @media(max-width:768px){
          font-size: 13px;
        }
      }
    }
    &__check{
      display: flex;
      align-items: center;
      font-size:  min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.5208))), 15px);
      color: #36363680;
      gap: min(max(8px, calc(0.5rem + ((1vw - 7.68px) * 0.6076))), 15px);
      @media(max-width:768px){
          font-size: 13px;
        }
    }
    button{
      width: 40%;
      background: #006495;
      border: none;
      color: white;
      border-radius: 5px;
      padding: min(max(8px, calc(0.5rem + ((1vw - 7.68px) * 0.3472))), 12px) 0px;
      font-size:  min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.5208))), 15px);
      @media(max-width:768px){
          font-size: 13px;
          width: 60%;
        }
    }
  }
  &__right{
    display: flex;
    flex-direction: column;
    gap:  min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.6944))), 20px);
    @media(max-width:768px){
          width: 75%;
        }
    &__title{
      font-size: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.5208))), 18px);
      @media(max-width:768px){
          font-size: 13px;
        }
    }
    &__choose{
      border: 1px solid #36363626;
      padding:  min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 1.1285))), 25px);
      display: flex;
      gap:  min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 1.1285))), 25px);
      &__1{
        display: flex;
        gap: 10px;
        &__in{
          display: flex;
          flex-direction: column;
          gap: 15px;
          align-items: center;
          font-size: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.5208))), 15px);
          color: #006495;
          @media(max-width:768px){
            font-size: 11px;
          }
          img{
            width: 100%;
            height: min(max(30px, calc(1.875rem + ((1vw - 7.68px) * 2.6042))), 60px);
          }
        }
      }
    }
  }
}
}
.placing{
display: flex;
flex-direction: column;
gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 1.3021))), 30px);
&__title{
  font-size: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.7813))), 24px);
  display: flex;
  justify-content: space-between;
}
&__choose{
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media(max-width:768px){
    align-items: start;
    flex-direction: column;
    gap: 10px;
  }
  &__type{
    display: flex;
    height: 100px;
    width: 35%;
    flex-direction: column;
    gap: min(max(7px, calc(0.4375rem + ((1vw - 7.68px) * 0.6944))), 15px);
    padding: min(max(7px, calc(0.4375rem + ((1vw - 7.68px) * 0.6944))), 15px) min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 1.1285))), 25px);
    border: 1px solid #36363626;
    box-shadow: 0px 4px 16px 0px #4098FF26;
    border-radius: 5px;
    font-size: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.6944))), 18px);
    @media(max-width:768px){
      width: 70%;
      font-size: 13px;
    }
    &__inputs{
      display: flex;
      gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);
      font-size: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.6944))), 18px);
      height: auto;
      @media(max-width:768px){
        font-size: 13px;
      }
      &__input{
        display: flex;
        gap: 10px;
        color: #36363680;
        align-items: center;
      }
    }
  }
  button{
    width: 20%;
    background: #006495;
    font-size: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.6944))), 18px);
    @media(max-width:768px){
        font-size: 11px;
        width: 30%;
      }
    color: white;
        padding: 10px;
        height: fit-content;
        border: none;
        border-radius: 5px;

  }
}
&__products{
  display: flex;
  flex-direction: column;
  gap:  min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 1.1285))), 25px);
  &__in{
    display: flex;
    width: 75%;
    border: 1px solid #36363626;
    box-shadow: 0px 4px 16px 0px #4098FF26;
    padding: min(max(6px, calc(0.375rem + ((1vw - 7.68px) * 0.3472))), 10px) min(max(20px, calc(1.25rem + ((1vw - 7.68px) * 2.1701))), 45px) min(max(6px, calc(0.375rem + ((1vw - 7.68px) * 0.3472))), 10px) min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.8681))), 20px);
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    @media(max-width:768px){
      width: 100%;
    }
    cursor: pointer;
              transition: all 0.5s ease;
              &:hover{
                transform: scale(1.05);
              }
    &__image{
      width: 10%;
      @media(max-width:768px){
        width: fit-content;
      }
      img{
        width: min(max(50px, calc(3.125rem + ((1vw - 7.68px) * 6.0764))), 120px);
        height: min(max(40px, calc(2.5rem + ((1vw - 7.68px) * 5.2083))), 100px);
        @media(max-width:768px){
          width: 90px;
          height: 75px;
        }
      }
    }
    &__desc{
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      p{
        font-size: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.5208))), 18px);
        @media(max-width:768px){
          font-size: 13px;
        }
      }
      &__articul{
        color: #36363680;
        font-size: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.5208))), 15px) !important;
        @media(max-width:768px){
          font-size: 11px;
        }
      }
    }
    &__count{
      width: 10%;
      font-size: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.5208))), 15px);
      border: 1px solid #00000026;
      padding: 5px;
      display: flex;
      justify-content: center;
      color: #4098FF;
      border-radius: 5px;
      @media(max-width:768px){
        font-size: 11px;
      }
    }
    &__price{
      font-size:  min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.5208))), 18px);
      @media(max-width:768px){
        font-size: 13px;
      }
    }
  }
}
}
  .basket{
      display: flex;
      flex-direction: column;
      gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 1.3021))), 30px);
      &__title{
          color: #363636;
          font-size: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.7813))), 24px);
          @media(max-width:768px){
              font-size: 15px;
          }
      }
      &__sum{
        display: flex;
        justify-content: space-between;
        &__price{
          border: 1px solid #36363626;
          box-shadow: 0px 4px 16px 0px #4098FF26;
          padding: min(max(6px, calc(0.375rem + ((1vw - 7.68px) * 0.3472))), 10px) min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 1.1285))), 25px);
          width: 75%;
          border-radius: 5px;
          font-size: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.5208))), 18px);
          @media(max-width:768px){
              font-size: 13px;
          }
        }
        button{
          color: white;
          background: #006495;
          width: 20%;
          border: none;
          border-radius: 5px;
          font-size: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.5208))), 18px);
          @media(max-width:768px){
              font-size: 12px;
          }
        }
      }
      &__products{
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(min(max(150px, calc(9.375rem + ((1vw - 7.68px) * 8.6806))), 250px), 1fr));
      gap: min(max(30px, calc(1.875rem + ((1vw - 7.68px) * 2.6042))), 60px);
      row-gap: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);
      &__not{
        font-size: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.5208))), 18px);
        @media(max-width:768px){
            font-size: 14px;
        }
      }
          &__in{
              border: 1px solid #0000001A;
              border-radius: 10px;
              padding: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 1.0417))), 24px);
              display: flex;
              flex-direction: column;
              gap: min(max(7px, calc(0.4375rem + ((1vw - 7.68px) * 0.434))), 12px);
              font-size: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.5208))), 15px);
              @media(max-width:768px){
                  font-size: 13px;
              }
              color: #00000080;
              cursor: pointer;
              transition: all 0.5s ease;
              &:hover{
                transform: scale(1.05);
              }
              &__count{
                display: flex;
                justify-content: space-between;
                align-items: center;
                div{
                  border: 1px solid #00000026;
                  border-radius: 5px;
                }
                &__plus{
                  padding: min(max(3px, calc(0.1875rem + ((1vw - 7.68px) * 0.2604))), 6px) min(max(5px, calc(0.3125rem + ((1vw - 7.68px) * 0.3472))), 9px);
                }
                &__minus{
                  padding: min(max(3px, calc(0.1875rem + ((1vw - 7.68px) * 0.2604))), 6px) min(max(5px, calc(0.3125rem + ((1vw - 7.68px) * 0.3472))), 9px);
                }
                &__number{
                  width: 50%;
                  padding: 5px 0px;
                  display: flex;
                  justify-content: center;
                }
              }
              &__img{
                  width: 100%;
                  aspect-ratio: 1;
              }
              &__title{
                  color: #232F3E;
                  flex-grow: 1;
              }
              &__1{
                  display: flex;
                  gap: 10px;
              }
              &__price{
                  font-size: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.5208))), 18px) !important;
                  color: black !important;
                  @media(max-width:768px){
                    font-size: 13px !important;
                }
              }
              &__bot{
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  color: red;
              }
          }
      }
  }
</style>    