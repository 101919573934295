<template>
    <div class="contact container">
        <Table />
        <div class="contact__right">
            <Bread :title1="'Главная'" :title2="'Контакты'" :link1="'/'" />
            <div class="contact__right__title">
                <p>Контакты</p>
            </div>
            <div class="contact__right__block">
                <div class="contact__right__block__title">
                    <p>Интернет-магазин HAYWARD</p>
                </div>
                <div class="contact__right__block__address">
                    <img src="@/assets/icons/marker.svg"/>
                    <p>{{ data.address }}</p>
                </div>
                <div class="contact__right__block__info">
                    <div class="contact__right__block__info__top">
                        <a :href="`tel:${data.phone}`" style="color: inherit; text-decoration: none;"><img src="@/assets/icons/call1.svg" /></a>
                        <p><a :href="`tel:${data.phone}`" style="color: inherit; text-decoration: none;">{{ data.phone }}</a></p>
                    </div>
                    <div class="contact__right__block__info__bot">
                        <img src="@/assets/icons/gmail.svg" />
                        <p>{{ data.email }}</p>
                    </div>
                </div>
                <div class="contact__right__block__map">
                    <div class="contact__right__block__map__title">
                        <p>Схема проезда</p>
                    </div>
                    <div class="contact__right__block__map__bot">
                        <div class="mapouter">
                            <div class="gmap_canvas"><iframe class="gmap_iframe" frameborder="0" scrolling="no"
                                    marginheight="0" marginwidth="0" :src="data.map_link"></iframe></div>
                        </div>
                    </div>
                </div>
                <div class="contact__right__block__inputs">
                    <div class="contact__right__block__inputs__title">
                        <p>Форма обратной связи</p>
                    </div>
                    <div class="contact__right__block__inputs__sub">
                        <p>Заполните форму обратной связи</p>
                    </div>
                    <div class="contact__right__block__inputs__input">
                        <input type="text" placeholder="Имя*" v-model="v$.name.$model" />
                        <template v-for="(error) of v$.name.$errors" :key="error">
                            <p class="errorValid">{{ error.$message }}</p>
                        </template>
                    </div>
                    <div class="contact__right__block__inputs__input">
                        <input type="email" placeholder="E-mail*" v-model="v$.email.$model" />
                        <template v-for="(error) of v$.email.$errors" :key="error">
                            <p class="errorValid">{{ error.$message }}</p>
                        </template>
                    </div>
                    <div class="contact__right__block__inputs__input">
                        <input type="text" placeholder="Сообщения*" v-model="message" />
                    </div>
                    <div class="contact__right__block__inputs__bot">
                        <div class="contact__right__block__inputs__bot__title">
                            <p>Подтвердите что вы не робот</p>
                        </div>
                        <vue-recaptcha v-show="showRecaptcha" sitekey="6Le4jT0pAAAAAG_mU0kf6_vDNEeYx0dfgn5vXdvT"
                            size="normal" theme="light" hl="ru" :loading-timeout="loadingTimeout"
                            @verify="recaptchaVerified" @expire="recaptchaExpired" @fail="recaptchaFailed"
                            @error="recaptchaError" ref="vueRecaptcha">
                        </vue-recaptcha>
                    </div>
                </div>
                <div class="contact__right__block__check">
                    <div class="contact__right__block__check__top">
                        <input type="checkbox" v-model="v$.check.$model" />
                        <p>При отправке данной формы Вы подтверждаете свою<br />дееспособность<br />и согласие на <span
                                style="text-decoration: underline; color:#006495;">обработку персональных данных</span></p>
                    </div>
                    <div class="contact__right__block__check__bot">
                        <template v-for="(error) of v$.check.$errors" :key="error">
                            <p class="errorValid">{{ error.$message }}</p>
                        </template>
                    </div>
                </div>
                <div class="contact__right__block__button">
                    <button @click="sendData()">Отправить</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Bread from '@/components/Bread.vue';
import Table from '@/components/Table.vue';
import vueRecaptcha from 'vue3-recaptcha2';
import axios from 'axios'
import { useVuelidate } from '@vuelidate/core'
import { required, minLength, helpers, email, sameAs } from "@vuelidate/validators";
import { toast } from 'vue3-toastify';
import { mapMutations } from 'vuex';
export default {
    setup() {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            data: false,
            showRecaptcha: true,
            loadingTimeout: 30000,
            name: '',
            email: '',
            message: '',
            check: ''
        }
    },
    components: {
        Table, Bread, vueRecaptcha
    },
    async created() {
        this.getPage()
    },
    methods: {
        ...mapMutations(['SET_LOADING']),
        async getPage() {
            this.SET_LOADING(true) 
            await this.$axios.get(`pages/contacts?lang=ru`)
                .then(res => {
                    this.data = res.data.contact
                    this.SET_LOADING(false) 
                })
                .catch(err => {
                    this.SET_LOADING(false) 
                })
        },
        recaptchaVerified(response) {
            // Здесь вы можете выполнять дополнительные действия, связанные с успешной проверкой reCAPTCHA
            this.showRecaptcha = false; // Устанавливаем флаг в false после успешной проверки reCAPTCHA
        },
        recaptchaExpired() {
            this.$refs.vueRecaptcha.reset();
        },
        recaptchaFailed() {
        },
        recaptchaError(reason) {
        },
        sendData() {
            if (!this.v$.$invalid) {
                if (this.showRecaptcha) {
                    // Вы можете проверить успешность прохождения reCAPTCHA через обработчик @verify
                    // Проверка происходит внутри метода recaptchaVerified
                    toast.error('Пройдите проверку reCAPTCHA');
                } else {
                    const back = {
                        phone: '',
                        name: this.name,
                        email: this.email,
                        message: this.message
                    };
                    axios.post("https://back-hayward.mydev.kz/api/V1/applications/create", back, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                        }
                    }).then((response) => {
                        if (response.status === 201) {
                            toast('Заявка успешно отправлена');
                        } else {
                            toast(err?.response?.message);
                        }
                    }).catch((e) => {
                        console.log(e);
                    });
                }
            } else {
                this.v$.$touch();
            }
        }
    },
    validations() {
        return {
            name: {
                required: helpers.withMessage('Обязательное поле', required),
                minLength: helpers.withMessage('Минимальное количество символов: 3', minLength(3)),
            },
            email: {
                required: helpers.withMessage('Обязательное поле', required),
                email: helpers.withMessage('Неправильный формат', email)
            },
            check: {
                required: helpers.withMessage('Обязательное поле', required),
            }
        };
    },
}
</script>
<style lang="scss" scoped>
.errorValid {
    font-size: 12px !important;
    color: #FF2A62 !important;
    margin-top: 8px;
}

.x {
    position: relative;
    text-align: right;
    width: min(max(450px, calc(28.125rem + ((1vw - 7.68px) * 39.0625))), 900px);
    height: min(max(250px, calc(15.625rem + ((1vw - 7.68px) * 21.7014))), 500px);
    border-radius: 10px;
    @media(max-width:768px){
        width: 100%;
        height: 40vh;        
    }
}

.gmap_canvas {
    overflow: hidden;
    background: none !important;
    width: min(max(450px, calc(28.125rem + ((1vw - 7.68px) * 39.0625))), 900px);
    height: min(max(250px, calc(15.625rem + ((1vw - 7.68px) * 21.7014))), 500px);
    border-radius: 10px;
    @media(max-width:768px){
        width: 100%;
        height: 40vh;        
    }
}

.gmap_iframe {
    width: min(max(450px, calc(28.125rem + ((1vw - 7.68px) * 39.0625))), 900px) !important;
    height: min(max(250px, calc(15.625rem + ((1vw - 7.68px) * 21.7014))), 500px) !important;
    border-radius: 10px;
    @media(max-width:768px){
        width: 100%;
        height: 40vh;        
    }
}

.contact {
    display: flex;
    justify-content: space-between;
    padding-top: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 1.1285))), 25px);
    padding-bottom: min(max(75px, calc(4.6875rem + ((1vw - 7.68px) * 6.5104))), 150px);
    &__right {
        width: 74.07%;
        display: flex;
        flex-direction: column;
        gap: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 1.1285))), 25px);
        @media(max-width:768px) {
            width: 100%;
            gap: 15px;
        }
        &__title {
            font-size: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 0.6944))), 23px);
            font-weight: 600;
            @media(max-width:768px){
                font-size: 16px;
            }
        }
        &__block {
            border: 1px solid #3636361A;
            padding: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 1.3021))), 30px) min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 1.3021))), 30px) min(max(30px, calc(1.875rem + ((1vw - 7.68px) * 5.2083))), 90px) min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 1.3021))), 30px);
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.8681))), 20px);
            @media(max-width:768px) {
                padding: 20px;
                gap: 15px;
            }

            &__title {
                font-size: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.6944))), 22px);
                @media(max-width:768px){
                    font-size: 15px;
                }
            }

            &__address {
                display: flex;
                gap: 10px;
                align-items: center;
                color: #36363680;
                font-size: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.5208))), 18px);
                img{
                    width:  min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 1.3021))), 30px);
                        height:  min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 1.3021))), 30px);
                        @media(max-width:768px){
                            width: 20px;
                            height: 20px;
                        }
                }
                @media(max-width:768px){
                    font-size: 14px;
                }
            }

            &__info {
                display: flex;
                flex-direction: column;
                gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.8681))), 20px);
                font-size: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.5208))), 18px);
                @media(max-width:768px){
                    font-size: 14px;
                }
                &__top {
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    img{
                        width:  min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 1.3021))), 30px);
                        height:  min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 1.3021))), 30px);
                        @media(max-width:768px){
                            width: 20px;
                            height: 20px;
                        }
                    }
                }

                &__bot {
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    img{
                        width:  min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 1.3021))), 30px);
                        height:  min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 1.3021))), 30px);
                        @media(max-width:768px){
                            width: 20px;
                            height: 20px;
                        }
                    }
                }
            }

            &__map {
                display: flex;
                flex-direction: column;
                gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.8681))), 20px);
                font-size: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.5208))), 18px);
                @media(max-width:768px){
                    font-size: 14px;
                }
                &__title {
                    font-size: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.5208))), 18px);
                    color: black;
                    @media(max-width:768px){
                        font-size: 14px;
                    }
                }
            }

            &__inputs {
                display: flex;
                flex-direction: column;
                gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.8681))), 20px);
                &__title {
                    font-size: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.5208))), 18px);
                    @media(max-width:768px){
                        font-size: 14px;
                    }
                }
                &__sub {
                    width: 30%;
                    background: #4098FF;
                    font-size: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.5208))), 18px);
                    color: white;
                    padding: 8px 15px;
                    border-radius: 5px;
                    @media(max-width:768px){
                        width: 100%;
                        font-size: 14px;
                    }
                }

                &__input {
                    width: 30%;
                    @media(max-width:768px){
                        width: 100%;
                    }
                    input {
                        width: 100%;
                        padding: 8px 15px;
                        border-radius: 5px;
                        border: 1px solid #36363626;
                        outline: none;
                        font-size: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.5208))), 18px);
                        @media(max-width:768px){
                            font-size: 14px;
                        }
                    }
                }

                &__bot {
                    display: flex;
                    flex-direction: column;
                    gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.8681))), 20px);
                    font-size: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.5208))), 18px);
                    @media(max-width:768px){
                        font-size: 14px;
                    }
                    input {
                        width: 18%;
                        padding: 8px 15px;
                        border: 1px solid #36363626;
                        outline: none;
                        border-radius: 5px;

                    }
                }
            }

            &__check {
                font-size: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.5208))), 18px);
                @media(max-width:768px){
                    font-size: 14px;
                }
                &__top {
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    color: #36363680;
                }
            }

            &__button {
                width: 18%;
                @media(max-width:768px){
                        width: 100%;
                    } 
                button {
                    width: 100%;
                    cursor: pointer;
                    font-size: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.5208))), 18px);
                    background: #006495;
                    border: none;
                    color: white;
                    padding: 9px 0px;
                    border-radius: 5px;
                    @media(max-width:768px){
                        font-size: 14px;
                    }
                }
            }
        }
    }
}</style>