<template>
    <div class="search container">
        <Table />
        <div class="search__block">
            <Bread :title1="'Главная'" :title2="'Поиск'" :link1="'/'"/>
            <div class="search__block__title">
                <p>Результаты поиска</p>
            </div>
            <div class="search__block__results">
                <p>Поиск по запросу: <span style="color: #363636;">{{ this.$route.query.search }}</span></p>
            </div>
            <div class="search__block__count">
                <p>Найдено ({{ allDates.length }}) товара</p>
            </div>
            <div class="search__block__products" v-if="allDates.length > 0">
                <CardProduct :item="item" v-for="item in allDates" :key="item"/>
            </div>
            <div v-else>
                <p>Нет совпадений</p>
            </div>
        </div>
    </div>
</template>
<script>
import Table from '@/components/Table.vue';
import Bread from '@/components/Bread.vue';
import CardProduct from '@/components/CardProduct.vue';
import { mapMutations } from 'vuex';
export default {
    data(){
        return{
            allDates: []
        }
    },
    components: {
        Table, Bread, CardProduct
    },
    mounted() {
        if (this.$route.query.search) {
            const searchData = localStorage.getItem("searchResult");
            if (searchData) {
                try {
                    this.allDates = JSON.parse(searchData);
                } catch (error) {
                    console.error("Error parsing JSON:", error);
                }
            }
        }
    },
    watch: {
        "$route.query"() {
            if (this.$route.query.search) {
                const searchData = localStorage.getItem("searchResult");
                if (searchData) {
                    try {
                        this.allDates = JSON.parse(searchData);
                    } catch (error) {
                        console.error("Error parsing JSON:", error);
                    }
                }
            }
        }
    }
}
</script>
<style lang="scss" scoped>
    .search{
        display: flex;
        justify-content: space-between;
        padding-top: 25px;
        padding-bottom: 150px;
        &__block{
            width: 74.07%;
            display: flex;
            flex-direction: column;
            gap: 25px;
            &__title{
                font-size: 20px;
                font-weight: 600;
            }
            &__results{
                border: 1px solid #00000033;
                padding: 12px 15px;
                width: 70%;
                border-radius: 10px;
                color: #00000080;
            }
            &__count{
                color: #006495;
            }
            &__products{
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
                gap: 60px;
                row-gap: 50px;
            }
        }
    }
</style>