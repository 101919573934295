<template>
  <div class="home__block1__left" :class="{activeLeft: $store.state.openTable == true}">
    <div class="home__block1__left__title" :class="{activeLeftBlock: $store.state.openTable == true}">
      <p>Каталог</p>
    </div>
    <div class="home__block1__left__catalog" :class="{activeLeftBlock: $store.state.openTable == true}">
      <div class="home__block1__left__catalog__in" v-for="item in header.categories" :key="item">
        <div class="home__block1__left__catalog__in__text" :class="{ activeCategory: (item.id == categoryId || item.id == $route.query.category_id) }"
          @click="catalogQuery(item.id), selectCategory(item.title)">
          <p style="cursor: pointer;">{{ item.title }}</p>
        </div>
        <div class="home__block1__left__catalog__in__subcategories" v-if="(item.id == categoryId || item.id == $route.query.category_id) && item.subCategories?.length > 0">
          <div class="home__block1__left__catalog__in__subcategories__in" v-for="sub in item.subCategories" :key="sub.id">
            <div class="home__block1__left__catalog__in__subcategories__in__title" style="cursor: pointer;">
              <p @click="subCategoryId = sub.id, subCatalogQuery(sub.id)"
                :class="{ activeSubCategory: sub.id == subCategoryId }">{{ sub.title }}</p>
            </div>
            <div class="line">
            </div>
          </div>
        </div>
        <div class="line"></div>
      </div>
    </div>
    <!-- <div class="home__block1__left__filter" v-if="$route.name == 'catalog'">
      <div class="home__block1__left__filter__title">
        <img src="@/assets/icons/filter.svg" />
        <p>Фильтр</p>
      </div>
      <div class="home__block1__left__filter__values" v-if="filters">
        <div class="home__block1__left__filter__values__in" v-for="item in filters" :key="item">
          <p>{{ item.title }}</p>
          <div class="checks" :class="{ 'heightScroll': item.filter_items.length > 3 }">
            <div class="checks__in" v-for="check in item.filter_items" :key="check">
              <input type="checkbox" :value="check.id" v-model="filter_item_ids" />
              <p>{{ check.title }}</p>
            </div>
          </div>
          <div class="line"></div>
        </div>
      </div>
    </div> -->
    <p style="text-align: center; margin-top: 20px; font-size: 14px; color: red; cursor: pointer;" @click="resetFilters" v-if="$route.name == 'catalog'">Сбросить фильтры</p>
    <!-- <div class="home__block1__left__dopFilter" v-if="$route.name == 'catalog'"> -->
      <!-- <div class="home__block1__left__dopFilter__in" v-for="(item, index) in header.features" :key="item.id"> -->
        <!-- <div class="home__block1__left__dopFilter__in__inputs" v-if="item.type == 0"> -->
          <!-- <p>{{ item.title }}</p> -->
          <!-- <div class="home__block1__left__dopFilter__in__inputs__in" v-for="inputs in item.featureItems" :key="inputs">
            <input type="checkbox" :value="inputs.id" v-model="feature_item_ids" />
            <p>{{ inputs.title }}</p>
          </div> -->
        <!-- </div> -->
        <!-- <RangePrice v-if="item.type == 1" :title="item.title" v-model:min="item.featureItems.min" :max="item.featureItems.max" :step="1" :index="index" :range="[item.featureItems.min, item.featureItems.max]"
            @getValueWithRange="(min, max) => {
              $store.state.range.min = min,
              $store.state.range.max = max,
              $store.state.range.id = item.id
            }" /> -->
      <!-- </div> -->
    <!-- </div> -->
  </div>
</template>
<script>
import RangePrice from './RangePrice.vue'
export default {
  components: {
    RangePrice
  },
  props: ['filters'],
  data() {
    return {
      header: false,
      categoryId: '',
      subCategoryId: '',
      filter_item_ids: [],
      feature_item_ids: [],
      feature_item_ranges: [],
    }
  },
  async created() {
    this.getPage()
  },
  methods: {
    resetFilters() {
      this.$router.push({ path: '/catalog' });  
    },
    selectCategory(category) {
      // Отправить выбранную категорию в родительский компонент
      this.$emit('category-selected', category);
    },
    getValueForRange(value1, value2, value3) {
      const range = {
        id: value1,
        min: value2,
        max: value3
      };
      const existingIndex = this.feature_item_ranges.findIndex(item => item.id === range.id);
      if (existingIndex !== -1) {
        this.feature_item_ranges[existingIndex] = range;
      } else {
        this.feature_item_ranges.push(range);
      }
      console.log('Массив данных:', this.feature_item_ranges);
    },
    catalogQuery(id) {
      this.categoryId = id
      const currentQuery = { ...this.$route.query };
      if(id){
        currentQuery.category_id = this.categoryId;
        delete currentQuery['sub_category_id']
        delete currentQuery['page']
        this.subCategoryId = ''
      }else{
        delete currentQuery['category_id']
      }
      this.$router.push({ path: '/catalog', query: { ...currentQuery } });  
    },
    subCatalogQuery(id) {
      this.subCategoryId = id;
      const currentQuery = { ...this.$route.query };
      if(id){
        currentQuery.sub_category_id = this.subCategoryId;
        delete currentQuery['page']
      } else{
        delete currentQuery['sub_category_id']
      }
      this.$router.push({ path: '/catalog', query: { ...currentQuery } });
    },
    filterItemIds(value) {
      const currentQuery = { ...this.$route.query }
      if (value.length) {
        currentQuery.filter_item_ids = value.join();
      } else {
        delete currentQuery['filter_item_ids']
      }
      this.$router.push({ path: '/catalog', query: { ...currentQuery } });
    },
    featureItemIds(value) {
      const currentQuery = { ...this.$route.query }
      if(value.length){
        currentQuery.feature_item_ids = value.join();
      }else{
        delete currentQuery['feature_item_ids']
      }
      this.$router.push({ path: '/catalog', query: { ...currentQuery } });
    },
    async getPage() {
      await this.$axios.get(`get-header?lang=ru`)
        .then(res => {
          this.header = res.data
        })
        .catch(err => {
        })
    },
  },
  watch: {
    'filter_item_ids': function (newVal, oldVal) {
      this.filterItemIds(newVal);
    },
    'feature_item_ids': function (newVal, oldVal) {
      this.featureItemIds(newVal);
    },
  }
}
</script>
<style lang="scss" scoped>
.activeLeftBlock{
  @media(max-width:768px) {
        display: none !important;
      }
}
.activeSubCategory {
  color: red;
}
.activeCategory {
  color: white;
  background: #006495;
  border-radius: 10px;
  padding: 10px;
}
.heightScroll {
  overflow: auto;
  height: min(max(70px, calc(4.375rem + ((1vw - 7.68px) * 2.6042))), 100px);
}
.line {
  border: 1px solid #0000001A;
}
.checks {
  display: flex;
  flex-direction: column;
  gap: min(max(8px, calc(0.5rem + ((1vw - 7.68px) * 0.6944))), 16px);

  &__in {
    display: flex;
    gap: min(max(6px, calc(0.375rem + ((1vw - 7.68px) * 0.3472))), 10px);
    color: #4E4E4E;
    font-size: min(max(11px, calc(0.6875rem + ((1vw - 7.68px) * 0.434))), 16px);
    align-items: center;
  }
}
.activeLeft{
  width: inherit !important;
  position: absolute;
  top: 330px;
  z-index: 999;
  background: white;
  height: 400px !important;
  overflow: auto;
  @media(max-width:768px) {
        display: block !important;
      }
}
.home {
  &__block1 {
    display: flex;
    justify-content: space-between;
    padding-top: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 1.1285))), 25px);
    &__left {
      width: 22.63%;
      box-shadow: 0px 4px 16px 0px #00000026;
      border-radius: 10px;
      height: fit-content;
      padding-bottom: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 1.1285))), 25px);
      @media(max-width:768px) {
        display: none;
      }
      &__title {
        padding: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 1.1285))), 25px);
        background: #006495;
        color: white;
        border-radius: 10px 10px 0px 0px;
        font-size: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.5208))), 18px);
      }

      &__catalog {
        display: flex;
        flex-direction: column;
        gap: min(max(8px, calc(0.5rem + ((1vw - 7.68px) * 0.6944))), 16px);
        padding: 0px min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 1.1285))), 25px);
        padding-top: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 1.1285))), 25px);
        color: #4E4E4E;
        font-size: min(max(11px, calc(0.6875rem + ((1vw - 7.68px) * 0.434))), 16px);

        &__in {
          display: flex;
          flex-direction: column;
          gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.5208))), 16px);

          &__subcategories {
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding-left: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.6944))), 20px);
            padding-right: min(max(50px, calc(3.125rem + ((1vw - 7.68px) * 4.3403))), 100px);
            font-size: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.434))), 15px);

            &__in {
              display: flex;
              flex-direction: column;
              gap: 5px;
              transition: all 0.5s ease;

              &:hover {
                color: red;
                transform: scale(1.02);
              }

              &:last-child {
                .line {
                  display: none;
                }
              }
            }
          }

          &__text {
            transition: all 0.7s ease;

            &:hover {
              padding: 10px;
              background: #006495;
              border-radius: 10px;
              color: white;
            }
          }
        }
      }

      &__filter {
        display: flex;
        flex-direction: column;
        gap: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 1.1285))), 25px);
        padding: 0px min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 1.1285))), 25px);
        padding-top: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);

        &__title {
          display: flex;
          gap: min(max(6px, calc(0.375rem + ((1vw - 7.68px) * 0.3472))), 10px);
          font-size: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.5208))), 18px);
        }

        &__values {
          display: flex;
          flex-direction: column;
          gap: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 1.1285))), 25px);

          &__in {
            display: flex;
            font-size: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.5208))), 18px);
            flex-direction: column;
            gap: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.6944))), 20px);
          }
        }
      }

      &__dopFilter {
        display: flex;
        flex-direction: column;
        padding: 0px min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 1.1285))), 25px);
        padding-top: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);

        &__in {
          &__inputs {
            display: flex;
            flex-direction: column;
            gap: min(max(5px, calc(0.3125rem + ((1vw - 7.68px) * 0.6076))), 12px);

            p {
              font-size: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.5208))), 18px);
            }

            &__in {
              display: flex;
              gap: min(max(5px, calc(0.3125rem + ((1vw - 7.68px) * 0.6076))), 12px);
              align-items: center;

              p {
                font-size: min(max(11px, calc(0.6875rem + ((1vw - 7.68px) * 0.434))), 16px) !important;
              }
            }
          }
        }
      }
    }
  }
}
</style>