<template>
  <div class="registerBack">
    <div class="register">
      <div class="register__modal">
        <div class="register__modal__title">
          <p>Регистрация</p>
        </div>
        <div class="register__modal__inputs">
          <div class="register__modal__inputs__input">
            <input type="text" placeholder="Имя*" v-model="v$.firstname.$model" />
            <template v-for="(error) of v$.firstname.$errors" :key="error">
              <p class="errorValid">{{ error.$message }}</p>
            </template>
          </div>
          <div class="register__modal__inputs__input">
            <input type="text" placeholder="Фамилия*" v-model="v$.lastname.$model" />
            <template v-for="(error) of v$.lastname.$errors" :key="error">
              <p class="errorValid">{{ error.$message }}</p>
            </template>
          </div>
          <div class="register__modal__inputs__input">
            <input type="email" placeholder="Email*" v-model="v$.email.$model" />
            <template v-for="(error) of v$.email.$errors" :key="error">
              <p class="errorValid">{{ error.$message }}</p>
            </template>
          </div>
          <div class="register__modal__inputs__input">
            <input type="password" placeholder="Пароль*" v-model="v$.password.$model" />
            <template v-for="(error) of v$.password.$errors" :key="error">
              <p class="errorValid">{{ error.$message }}</p>
            </template>
          </div>
          <div class="register__modal__inputs__input">
            <input type="password" placeholder="Повторите пароль*" v-model="v$.confirmPassword.$model" />
            <template v-for="(error) of v$.confirmPassword.$errors" :key="error">
              <p class="errorValid">{{ error.$message }}</p>
            </template>
          </div>
        </div>
        <div class="register__modal__check">
          <input type="checkbox" />
          <p>При отправке данной формы Вы подтверждаете свою дееспособность и согласие на <span
              style="color:#006495 ;">обработку персональных данных</span></p>
        </div>
        <div class="register__modal__button">
          <button @click="Register" style="cursor: pointer;">Зарегистрироваться</button>
        </div>
        <div class="register__modal__login">
          <p>Уже есть аккаунт?</p>
          <p style="color: #006495; cursor: pointer;" @click="$router.push('/login')">Войти</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useVuelidate } from '@vuelidate/core'
import { required, minLength, helpers, email, sameAs } from "@vuelidate/validators";
import { mask } from "vue-the-mask";
import { toast } from 'vue3-toastify';
import { mapGetters } from 'vuex';
import 'vue3-toastify/dist/index.css';
export default {
  directives: {
    mask
  },
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      confirmPassword: ''
    }
  },
  validations() {
    return {
      firstname: {
        required: helpers.withMessage('Обязательное поле', required),
        minLength: helpers.withMessage('Слишком короткое имя', minLength(3)
        ),
      },
      lastname: {
        required: helpers.withMessage('Обязательное поле', required)
      },
      email: {
        required: helpers.withMessage('Обязательное поле', required),
        email: helpers.withMessage('Некорректный email', email),
      },
      confirmPassword: { sameAs: helpers.withMessage('Пароль должен совпадать с вашим оригинальном паролем', sameAs(this.password)) },
      password: {
        required: helpers.withMessage('Обязательное поле', required),
        minLength: helpers.withMessage('Пароль должен содержать 8 символов', minLength(8)),
      }
    };
  },
  methods: {
    async Register() {
      this.v$.$validate();
      if (!this.v$.$invalid) {
        const data = {
          first_name: this.firstname,
          last_name: this.lastname,
          email: this.email,
          password: this.password
        };

        await this.$axios.post(`auth/registration`, data)
          .then(res => {
            // localStorage.setItem("access_token", res.data.user.original.access_token)
            toast('Вы успешно прошли регистрацию');
            // this.requestUser();
            this.$router.push('/login');
          })
          .catch(err => {
            if (err?.response?.data?.errors?.email) {
              toast('E-mail уже зарегистрирован');
            }
          })
      }
      else {
        toast("Заполните обязательные поля.");
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.errorValid {
  font-size: 12px !important;
  color: #FF2A62 !important;
}

.registerBack {
  padding: min(max(75px, calc(4.6875rem + ((1vw - 7.68px) * 6.5104))), 150px) 0px min(max(40px, calc(2.5rem + ((1vw - 7.68px) * 3.4722))), 80px) 0px;
  z-index: 999;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.register {
  display: flex;
  flex-direction: column;
  gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 1.3021))), 30px);
  align-items: center;
  width: 28.28%;
  @media(max-width:768px){
    width: 80%;
  } 
  &__logo{
    width: 100%;
    img{
      width: 100%;
    }
  }
  &__modal {
    padding: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px);
    border: 1px solid #36363633;
    box-shadow: 0px 4px 16px 0px #00000073;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 1.3021))), 30px);
    align-items: center;
    &__title {
      font-size: min(max(18px, calc(1.125rem + ((1vw - 7.68px) * 1.0417))), 30px);
      @media(max-width: 768px){
        font-size: 20px;
      }
    }

    &__inputs {
      display: flex;
      flex-direction: column;
      gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.8681))), 20px);
      width: 80%;
      padding-top: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.8681))), 20px);
      input {
        width: 100%;
        border: 1px solid #36363633;
        padding: 9px 15px;
        border-radius: 5px;
        outline: none;
        font-size: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.7813))), 21px);
        @media(max-width: 768px){
          font-size: 12px;
        }
      }
    }

    &__check {
      display: flex;
      gap: min(max(8px, calc(0.5rem + ((1vw - 7.68px) * 0.6076))), 15px);
      font-size: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.5208))), 18px);
      color: #36363680;
      @media(max-width: 768px){
        font-size: 12px;
      }
    }
    &__login{
      display: flex;
      gap: 5px;
      margin-top: -20px;
      font-size: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.5208))), 18px);
      @media(max-width: 768px){
        font-size: 12px;
        margin-top: -10px;
      }
    }

    &__button {
      width: 60%;
      button {
        padding: 10px 0px;
        font-size: min(max(12px, calc(0.75rem + ((1vw - 7.68px) * 0.5208))), 18px);
        width: 100%;
        background: #006495;
        color: white;
        border: none;
        border-radius: 5px;
        @media(max-width: 768px){
          font-size: 12px;
        }
      }
    }
  }
}</style>