<template>
    <div class="resetBack">
        <div class="reset">
            <div class="reset__logo">
                <router-link to="/"><img src="@/assets/icons/logo.svg" /></router-link>
            </div>
            <div class="reset__modal">
                <div class="reset__modal__title">
                    <p>Сброс пароля</p>
                </div>
                <div class="reset__modal__sub">
                    <p>Используйте пароль, который вы создадите ниже.</p>
                </div>
                <div class="reset__modal__inputs">
                    <div class="reset__modal__inputs__input">
                        <input type="email" :placeholder="$route.query.email" disabled />
                    </div>
                    <div class="reset__modal__inputs__input">
                        <input type="password" placeholder="Пароль*" v-model="v$.password.$model" />
                        <template v-for="(error) of v$.password.$errors" :key="error">
                            <p class="errorValid">{{ error.$message }}</p>
                        </template>
                    </div>
                    <div class="reset__modal__inputs__input">
                        <input type="password" placeholder="Повторите пароль*" v-model="v$.confirmPassword.$model" />
                        <template v-for="(error) of v$.confirmPassword.$errors" :key="error">
                            <p class="errorValid">{{ error.$message }}</p>
                        </template>
                    </div>
                </div>
                <div class="reset__modal__button">
                    <button @click="saveData()" style="cursor: pointer;">Сохранить</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { useVuelidate } from '@vuelidate/core'
import { required, minLength, helpers, email, sameAs } from "@vuelidate/validators";
import { mask } from "vue-the-mask";
import { toast } from 'vue3-toastify';
import { mapGetters } from 'vuex';
import 'vue3-toastify/dist/index.css';
export default {
    setup() {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            password: '',
            confirmPassword: ''
        }
    },
    validations() {
        return {
            confirmPassword: { sameAs: helpers.withMessage('Пароль должен совпадать с вашим оригинальном паролем', sameAs(this.password)) },
            password: {
                required: helpers.withMessage('Обязательное поле', required),
                minLength: helpers.withMessage('Пароль должен содержать 8 символов', minLength(8)),
            }
        };
    },
    methods: {
        saveData() {
            if (!this.v$.$invalid) {
                const back = {
                    email: this.$route.query.email,
                    token: this.$route.query.token,
                    password: this.password
                };
                this.$axios.post("auth/reset-password", back)
                    .then(res => {
                        toast('Успешно сохранено!')
                        this.$route.push('/')
                    })
                    .catch(err => {
                        if (err?.response?.data?.message) {
                            toast(err?.response?.data?.message);
                        }
                    })
            } else {
                this.v$.$touch(); // Fix the typo here
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.errorValid {
    font-size: 12px !important;
    color: #FF2A62 !important;
}

.resetBack {
    padding: min(max(75px, calc(4.6875rem + ((1vw - 7.68px) * 6.5104))), 150px) 0px min(max(40px, calc(2.5rem + ((1vw - 7.68px) * 3.4722))), 80px) 0px;
    z-index: 999;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    @media(max-width:768px) {
        padding-top: 30px;
    }
}

.reset {
    display: flex;
    flex-direction: column;
    gap: min(max(20px, calc(1.25rem + ((1vw - 7.68px) * 0.8681))), 30px);
    align-items: center;
    width: 28.28%;

    @media(max-width:768px) {
        width: 80%;
    }

    &__logo {
        width: 100%;

        @media(max-width:768px) {
            width: 75%;
        }

        img {
            width: 100%;
        }
    }

    &__modal {
        padding: min(max(25px, calc(1.5625rem + ((1vw - 7.68px) * 2.1701))), 50px) min(max(30px, calc(1.875rem + ((1vw - 7.68px) * 5.2083))), 90px);
        width: 100%;
        border: 1px solid #36363633;
        box-shadow: 0px 4px 16px 0px #00000073;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        gap: min(max(15px, calc(0.9375rem + ((1vw - 7.68px) * 1.3021))), 30px);
        align-items: center;

        &__sub {
            font-size: min(max(9px, calc(0.5625rem + ((1vw - 7.68px) * 0.434))), 14px);
            text-align: center;
            color: #00000080;
        }

        &__title {
            font-size: min(max(14px, calc(0.875rem + ((1vw - 7.68px) * 0.8681))), 24px);

            @media(max-width:768px) {
                font-size: 18px;
            }
        }

        &__inputs {
            display: flex;
            flex-direction: column;
            gap: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.8681))), 20px);
            width: 100%;

            input {
                width: 100%;
                border: 1px solid #36363633;
                padding: 9px 15px;
                border-radius: 5px;
                outline: none;
                font-size: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.8681))), 20px);

                @media(max-width:768px) {
                    font-size: 14px;
                }
            }

            p {
                color: #006495;
                font-size: min(max(8px, calc(0.5rem + ((1vw - 7.68px) * 0.6944))), 16px);

                @media(max-width:768px) {
                    font-size: 12px;
                }
            }
        }

        &__register {
            display: flex;
            gap: 5px;
            margin-top: -20px;
            font-size: min(max(8px, calc(0.5rem + ((1vw - 7.68px) * 0.6944))), 16px);

            @media(max-width:768px) {
                font-size: 12px;
                margin-top: -10px;
            }
        }

        &__button {
            width: 70%;

            button {
                padding: 10px 0px;
                font-size: min(max(10px, calc(0.625rem + ((1vw - 7.68px) * 0.8681))), 20px);
                width: 100%;
                background: #006495;
                color: white;
                border: none;
                border-radius: 5px;
            }
        }
    }
}
</style>